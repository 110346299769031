<template>
  <div>
    <div class="container">
      <div class="row g-0 mb-5" style="min-height: 65vh !important">
        <div class="col-12">
          <h1
            class="display-5 mt-5 mb-5 d-flex justify-content-center align-items-center"
          >
            <span class="highlighted me-auto">Metrics.</span>
          </h1>
          <div class="row g-0">
            <div class="col-md-3 col-12">
              <h3>Utilization (count)</h3>
              <div class="card mb-3">
                <div class="card-body">
                  <bar-chart
                    :data="chartData"
                    :options="chartOptions"
                    style="height: 35vh !important"
                  />
                </div>
              </div>
              <p>
                Total Vehicles:
                {{ vehicles.length }} | Vehicles on rent:
                {{ reservations.length }}
              </p>
              <p>Target Vehicles on Rent: {{ Math.ceil(targetCars) }}</p>
            </div>
            <div class="col-md-3 offset-md-1 col-12">
              <h3>Utilization (%)</h3>

              <div class="card mb-3">
                <div class="card-body">
                  <donut-chart
                    :data="donutChartData"
                    :options="donutChartOptions"
                  />
                </div>
              </div>
              <p>
                Current Utilization:
                {{
                  vehicles.length > 0
                    ? (100 * (reservations.length / vehicles.length)).toFixed(2)
                    : 0
                }}%
              </p>
              <p>Target Utilization: {{ targetUtilizationRate }}</p>
            </div>
            <div class="col-md-3 offset-md-1 col-12">
              <h3>Calculator</h3>
              <p class="mt-3">
                Implied Revenue (/car/day): ${{ impliedRevenue.toFixed(2) }}
              </p>
              <input
                type="number"
                v-model.number="impliedRevenue"
                placeholder=""
                class="form-control"
                min="0"
                max="100"
              />
              <p class="mt-3">
                Implied Cost(/car/day): ${{ impliedCost.toFixed(2) }}
              </p>
              <input
                type="number"
                v-model.number="impliedCost"
                class="form-control"
                placeholder=""
                min="0"
                max="100"
              />
              <p
                class="mt-3"
                title="Formula: Implied Revenue Per Car Per Day * 30 Days * Number of Reservations"
              >
                Implied Month Forward (Revenue)
              </p>
              <h3 class="mt-3">
                =
                {{
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(
                    impliedRevenue.toFixed(2) * 30 * reservations.length
                  )
                }}
              </h3>
              <p
                class="mt-3"
                title="Formula: Implied Cost Per Vehicle Per Day * 30 Days * Number of Vehicles"
              >
                Implied Month Forward (Cost)
              </p>
              <h3 class="mt-3 mb-3">
                =
                {{
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(impliedCost.toFixed(2) * 30 * vehicles.length)
                }}
              </h3>
              <p
                class="mt-3"
                title="Formula: Implied Cost Per Vehicle Per Day * 30 Days * Number of Vehicles"
              >
                Implied Month Forward (Net)
              </p>
              <h3 class="mt-3 mb-3">
                =
                {{
                  new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(
                    impliedRevenue.toFixed(2) * 30 * reservations.length -
                      impliedCost.toFixed(2) * 30 * vehicles.length
                  )
                }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Bar, Doughnut } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement
);

export default {
  name: "MetricsView",
  data: function () {
    return {
      impliedCost: 35,
      impliedRevenue: 45,
    };
  },
  components: {
    BarChart: Bar,
    DonutChart: Doughnut,
  },
  computed: {
    reservations: function () {
      if (this.$store.state.user.regions.length === 0) {
        return [];
      }
      let timezone = this.$store.state.user.regions.find((region) => {
        return region.id == this.$store.state.region;
      }).timezone;
      let now = new Date(
        new Date().toLocaleString("en-US", { timeZone: timezone })
      );
      return this.$store.state.user.reservations.filter((reservation) => {
        let pickupTime = new Date(
          new Date(reservation.pickup_at).toLocaleString("en-US", {
            timeZone: reservation.pickup_timezone,
          })
        );
        let returnTime = new Date(
          new Date(reservation.return_at).toLocaleString("en-US", {
            timeZone: reservation.return_timezone,
          })
        );

        // Check if the current time is between pickup and return times and status is "On Rent" or "Pending"
        return (
          now >= pickupTime &&
          now <= returnTime &&
          (reservation.status === "On Rent" || reservation.status === "Pending")
        );
      });
    },
    vehicles: function () {
      if (this.$store.state.user.vehicles.length === 0) {
        return [];
      }
      return this.$store.state.user.vehicles.filter((vehicle) => {
        return (
          vehicle.region_id == this.$store.state.region &&
          vehicle.status != "Unavailable" &&
          vehicle.status != "Employee"
        );
      });
    },
    targetUtilizationRate: function () {
      if (
        this.vehicles.length === 0 ||
        this.impliedRevenue <= this.impliedCost
      ) {
        return 0;
      }

      const monthlyRevenuePerCar = this.impliedRevenue * 30;
      const monthlyCostPerCar = this.impliedCost * 30;

      const targetCars =
        (monthlyCostPerCar * this.vehicles.length) / monthlyRevenuePerCar;

      const targetUtilizationPercentage =
        (targetCars / this.vehicles.length) * 100;

      return targetUtilizationPercentage.toFixed(2) + "%";
    },
    targetCars: function () {
      if (
        this.vehicles.length === 0 ||
        this.impliedRevenue <= this.impliedCost
      ) {
        return 0;
      }

      const monthlyRevenuePerCar = this.impliedRevenue * 30;
      const monthlyCostPerCar = this.impliedCost * 30;

      const targetCars =
        (monthlyCostPerCar * this.vehicles.length) / monthlyRevenuePerCar;

      return targetCars;
    },
    chartData: function () {
      return {
        labels: ["Total", "On Rent"],
        datasets: [
          {
            label: "Vehicles",
            data: [this.vehicles.length, this.reservations.length],
            backgroundColor: ["#d6faff", "blue"],
            borderColor: ["#d6faff", "blue"],
            borderWidth: 1,
          },
        ],
      };
    },
    chartOptions: function () {
      return {
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false,
            },
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      };
    },
    donutChartData: function () {
      return {
        labels: ["On Rent", "Available"],
        datasets: [
          {
            data: [
              (100 * (this.reservations.length / this.vehicles.length)).toFixed(
                2
              ),
              100 -
                (
                  100 *
                  (this.reservations.length / this.vehicles.length)
                ).toFixed(2),
            ],
            backgroundColor: ["blue", "#d6faff"],
            borderWidth: 1,
          },
        ],
      };
    },
    donutChartOptions: function () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 60, // Adjust this value for different donut thicknesses
      };
    },
  },
};
</script>
