<template>
  <div>
    <div class="fluid-container">
      <div class="row g-0 d-flex" style="min-height: 65vh !important">
        <div class="my-auto">
          <div class="col-10 offset-1">
            <h1 class="display-5 mt-5 mb-3 text-center">
              <span class="highlighted"
                >Check your {{ this.$route.query.type }}.</span
              >
            </h1>
            <p class="mt-3 mb-3 text-center">
              We sent you a sign in link. <br />Please click it to proceed into
              the platform.
            </p>
            <button
              onclick="history.back()"
              class="btn btn-primary mt-3 text-center mx-auto d-block"
            >
              Go back
            </button>
          </div>
          <div class="col-12 mb-5"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "EmailSentView",
};
</script>
