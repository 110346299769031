<template>
  <div>
    <div class="container">
      <div class="row g-0" style="min-height: 65vh !important">
        <div class="col-12">
          <h1
            class="display-5 mt-5 mb-3 d-flex justify-content-center align-items-center"
          >
            <span class="highlighted me-auto">Schedule.</span>
            <select
              class="form-select w-25 mt-auto me-2"
              id="dateSelect"
              v-model="selected"
              @change="selectDate"
            >
              <option value="yesterday">Yesterday</option>
              <option value="today" selected>Today</option>
              <option value="tomorrow">Tomorrow</option>
            </select>
          </h1>
          <p v-if="!$store.state.user.reservations.length">
            Looks like you don't have access to any reservations! Contact your
            supervisor to get access.
          </p>
          <h3 class="mt-3" v-if="unassignedReservations.length > 0">
            Unassigned
          </h3>
          <!-- Active Reservations -->
          <div
            class="table-responsive"
            v-if="unassignedReservations.length > 0"
          >
            <table class="table table-striped mb-3">
              <thead class="border-none">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Vehicle</th>
                  <th scope="col" class="text-end">Action</th>
                </tr>
              </thead>

              <tbody class="mt-3">
                <tr
                  v-for="reservation of unassignedReservations"
                  :key="
                    'reservation-cleaning-unassigned-' +
                    reservation.platform_id +
                    '-' +
                    reservation.type
                  "
                >
                  <td class="align-middle">{{ reservation.platform_id }}</td>
                  <td class="align-middle">
                    <span
                      class="badge badge-primary"
                      v-if="!reservation.vehicle_vin"
                      >!</span
                    >
                  </td>

                  <td class="align-middle text-end">
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      data-bs-toggle="offcanvas"
                      data-bs-target="
                                  #reservationOffcanvas-schedule
                                "
                      @click="id = reservation.id"
                    >
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3 class="mt-3">Assigned</h3>
          <!-- Active Reservations -->
          <div class="table-responsive">
            <table class="table mb-3 table-striped">
              <thead class="border-none">
                <tr>
                  <th scope="col">Order</th>
                  <th scolpe="col">Type</th>
                  <th scope="col">ID</th>
                  <th scope="col">VIN</th>
                  <th scope="col">Cleaning</th>
                  <th scope="col">Status</th>
                  <th scope="col">Location</th>
                  <th scope="col">Time</th>
                  <th scope="col">Assigned</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-if="
                    sortedPickups.length === 0 && sortedReturns.length === 0
                  "
                >
                  <td colspan="8" style="background-color: #f7f7f7">
                    No results
                  </td>
                </tr>
              </tbody>
              <tbody class="mt-3">
                <tr
                  v-for="reservation of filteredReservations"
                  :key="
                    'reservation-cleaning-' +
                    reservation.platform_id +
                    '-' +
                    reservation.type
                  "
                >
                  <td class="align-middle" style="min-width: 150px !important">
                    <!--<span
                      class="badge badge-tertiary"
                      v-if="reservation.order != 1000"
                      >{{ reservation.order }}</span
                    >-->
                    <input
                      type="number"
                      class="form-control"
                      v-model="reservation.order"
                      @input="debounceUpdateOrder(reservation)"
                      placeholder=""
                    />
                  </td>

                  <td class="align-middle">
                    <span class="badge badge-tertiary">{{
                      reservation.type[0].toUpperCase()
                    }}</span>
                  </td>
                  <td class="align-middle">
                    {{ reservation.vehicle_vin }}
                  </td>

                  <td class="align-middle">{{ reservation.platform_id }}</td>
                  <td class="align-middle">
                    <span
                      class="badge badge-tertiary"
                      v-if="reservation.action === true"
                      >Complete</span
                    >
                    <span
                      class="badge badge-primary"
                      v-if="reservation.action === false"
                      >Incomplete</span
                    >
                  </td>

                  <td
                    class="align-middle"
                    style="white-space: nowrap !important"
                  >
                    <span v-if="reservation.status == 'Pending'">Pending</span>
                    <span
                      v-if="
                        reservation.status == 'On Rent' &&
                        reservation.late == false
                      "
                      >On Rent</span
                    >
                    <span
                      v-if="
                        reservation.status == 'On Rent' &&
                        reservation.late == true
                      "
                      >On Rent (Late)</span
                    >
                    <span v-if="reservation.status == 'Canceled'"
                      >Canceled</span
                    >
                    <span
                      v-if="
                        reservation.status == 'Returned' &&
                        reservation.late == false
                      "
                      >Returned</span
                    >
                    <span
                      v-if="
                        reservation.status == 'Returned' &&
                        reservation.late == true
                      "
                      >Returned (Late)</span
                    >
                  </td>

                  <td class="align-middle">
                    {{
                      $store.state.user.locations.find((location) => {
                        return reservation.location_id == location.hq_id;
                      })
                        ? $store.state.user.locations.find((location) => {
                            return reservation.location_id == location.hq_id;
                          }).name
                        : ""
                    }}
                  </td>

                  <td class="align-middle">
                    {{
                      new Date(reservation.date).toLocaleDateString("en-US", {
                        timeZone: reservation.timezone,
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      })
                    }}
                  </td>

                  <td class="align-middle" style="min-width: 150px !important">
                    <select
                      class="form-control form-select d-inline ms-2"
                      :value="reservation.user_id"
                      @change="onAssignmentChange($event, reservation)"
                    >
                      <option value=""></option>
                      <option
                        :value="employee.user_id"
                        v-for="employee of $store.state.user.employees"
                        :key="'dispatch-employee-select-' + employee.user_id"
                      >
                        {{ employee.name.first_name }}
                        {{ employee.name.last_name }}
                      </option>
                    </select>
                  </td>
                  <td class="align-middle">
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      data-bs-toggle="offcanvas"
                      data-bs-target="
                                  #reservationOffcanvas-schedule
                                "
                      @click="id = reservation.id"
                    >
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-12 mb-5"></div>
      </div>
    </div>
    <!-- Reservation View -->
    <div
      class="offcanvas offcanvas-start"
      tabindex="-1"
      id="reservationOffcanvas-schedule"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div class="offcanvas-header align-middle">
        <h3 class="highlighted my-0 mt-0 fw-bold">View Reservation</h3>
        <button
          type="button"
          class="btn btn-primary"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          Close
        </button>
      </div>
      <div class="offcanvas-body">
        <ReservationOffcanvas :id="id"></ReservationOffcanvas>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ReservationOffcanvas from "@/components/offcanvas/ReservationOffcanvas.vue";

export default {
  name: "ScheduleView",
  data: function () {
    return {
      selected: "today",
      selectedDate: new Date(),
      id: "",
    };
  },
  created: function () {
    this.debounceUpdateOrder = this._.debounce(this.updateOrder, 2000);
  },
  components: {
    ReservationOffcanvas,
  },
  methods: {
    selectDate: function () {
      let selectedDate;
      const today = new Date();
      switch (this.selected) {
        case "yesterday":
          selectedDate = new Date(today.setDate(today.getDate() - 1));
          break;
        case "tomorrow":
          selectedDate = new Date(today.setDate(today.getDate() + 1));
          break;
        default:
          selectedDate = today;
      }
      // Format selectedDate as needed and perform actions
      console.log(selectedDate); // For demonstration
      // You can emit this date to parent component or use it within this component
      this.selectedDate = selectedDate;
    },
    onAssignmentChange: function (event, reservation) {
      const newUser = event.target.value;
      this.updateAssignment(reservation, newUser);
    },
    updateAssignment: function (reservation, user_id) {
      const suffix = reservation.type == "pickup" ? "pickup" : "return";
      const url = this.$store.state.root_url + "/reservation/user/" + suffix;
      this.$axios
        .post(
          url,
          {
            id: reservation.id,
            user_id: user_id,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateOrder(reservation) {
      // Log For Debug
      console.log("Order updated for reservation:", reservation);

      if (!Number.isInteger(Number(reservation.order))) {
        console.warn(
          "Order is not an integer, skipping update:",
          reservation.order
        );
        return; // Exit the method if not an integer
      }

      // Set Path
      const suffix = reservation.type == "pickup" ? "pickup" : "return";

      //Send Request
      const url =
        this.$store.state.root_url +
        "/reservation/schedule/" +
        suffix +
        "/order";

      this.$axios
        .post(
          url,
          {
            id: reservation.id,
            order: reservation.order,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  computed: {
    vehicles() {
      return this.$store.state.user.vehicles;
    },
    reservations() {
      return this.$store.state.user.reservations;
    },
    sortedReturns: function () {
      if (this.$store.state.user.regions.length === 0) {
        return [];
      }
      let filteredReservations = this.reservations;

      filteredReservations = filteredReservations.filter((reservation) => {
        return reservation.status != "Canceled" && reservation.extended != true;
      });

      const getDateTimeInZone = (date, timeZone, startOrEnd) => {
        // Convert the input date to the specified time zone first
        const zoneDate = new Date(
          date.toLocaleString("en-US", { timeZone: timeZone })
        );

        // Adjust the hours, minutes, seconds, and milliseconds based on startOrEnd
        if (startOrEnd === "start") {
          zoneDate.setHours(0, 0, 0, 0); // Set to start of the day in the target time zone
        } else {
          zoneDate.setHours(23, 59, 59, 999); // Set to end of the day in the target time zone
        }

        return zoneDate;
      };

      const today = new Date(this.selectedDate);
      const timeZone = this.$store.state.user.regions.find((region) => {
        return region.id == this.$store.state.user.region;
      }).timezone;

      const todayStart = getDateTimeInZone(today, timeZone, "start");
      const todayEnd = getDateTimeInZone(today, timeZone, "end");

      filteredReservations = filteredReservations.filter((reservation) => {
        const dropoffDate = new Date(
          new Date(reservation.return_at).toLocaleDateString("en-US", {
            timeZone: reservation.return_timezone,
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
        );

        return dropoffDate >= todayStart && dropoffDate <= todayEnd;
      });

      return filteredReservations;
    },
    sortedPickups: function () {
      if (this.$store.state.user.regions.length === 0) {
        return [];
      }
      let filteredReservations = this.reservations;

      filteredReservations = filteredReservations.filter((reservation) => {
        return reservation.status != "Canceled" && reservation.extended != true;
      });

      const getDateTimeInZone = (date, timeZone, startOrEnd) => {
        // Convert the input date to the specified time zone first
        const zoneDate = new Date(
          date.toLocaleString("en-US", { timeZone: timeZone })
        );

        // Adjust the hours, minutes, seconds, and milliseconds based on startOrEnd
        if (startOrEnd === "start") {
          zoneDate.setHours(0, 0, 0, 0); // Set to start of the day in the target time zone
        } else {
          zoneDate.setHours(23, 59, 59, 999); // Set to end of the day in the target time zone
        }

        return zoneDate;
      };

      const today = new Date(this.selectedDate);
      const timeZone = this.$store.state.user.regions.find((region) => {
        return region.id == this.$store.state.user.region;
      }).timezone;

      const todayStart = getDateTimeInZone(today, timeZone, "start");
      const todayEnd = getDateTimeInZone(today, timeZone, "end");

      filteredReservations = filteredReservations.filter((reservation) => {
        const pickupDate = new Date(
          new Date(reservation.pickup_at).toLocaleDateString("en-US", {
            timeZone: reservation.pickup_timezone,
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
        );

        return pickupDate >= todayStart && pickupDate <= todayEnd;
      });

      return filteredReservations;
    },
    combinedSortedReservations: function () {
      // Step 1: Merge and mark each reservation as a pickup or return for later processing
      let combinedReservations = [
        ...this.sortedPickups.map((reservation) => ({
          ...reservation,
          type: "pickup",
          date: reservation.pickup_at,
          action: reservation.pickup_photos,
          location_id: reservation.pickup_location_id,
          timezone: reservation.pickup_timezone,
          user_id: reservation.pickup_user_id,
          order: reservation.pickup_schedule_priority,
        })),
        ...this.sortedReturns.map((reservation) => ({
          ...reservation,
          type: "return",
          date: reservation.return_at,
          action: reservation.return_photos,
          location_id: reservation.return_location_id,
          timezone: reservation.return_timezone,
          user_id: reservation.return_user_id,
          order: reservation.return_schedule_priority,
        })),
      ];

      /*// Step 2: Sort by date (assuming pickup_at for pickups and return_at for returns)
      combinedReservations.sort((a, b) => {
        let dateA =
          a.type === "pickup" ? new Date(a.pickup_at) : new Date(a.return_at);
        let dateB =
          b.type === "pickup" ? new Date(b.pickup_at) : new Date(b.return_at);
        return dateA - dateB;
      });*/
      // Step 2: Sort reservations
      combinedReservations.sort((a, b) => {
        // Check if a or b has order 1000
        if (a.order === 1000 && b.order === 1000) {
          // If both are 1000, sort by time
          return new Date(a.date) - new Date(b.date);
        } else if (a.order === 1000) {
          return 1; // a comes after b
        } else if (b.order === 1000) {
          return -1; // b comes after a
        } else {
          // If neither is 1000, sort by order (ascending)
          if (a.order !== b.order) {
            return a.order - b.order;
          }
          // If orders are equal, sort by time
          return new Date(a.date) - new Date(b.date);
        }
      });

      // Step 4: Group by vehicle_vin
      return combinedReservations;
    },
    filteredReservations: function () {
      // Step 3: Filter out null VINs before grouping
      return this.combinedSortedReservations.filter(
        (reservation) =>
          reservation.vehicle_vin !== null &&
          reservation.vehicle_vin !== undefined
      );
    },
    groupedReservations: function () {
      return this._.groupBy(this.filteredReservations, "vehicle_vin");
    },
    unassignedReservations: function () {
      // Step 3: Filter out null VINs before grouping
      return this.combinedSortedReservations.filter(
        (reservation) =>
          reservation.vehicle_vin == null ||
          reservation.vehicle_vin == undefined ||
          reservation.vehicle_vin == ""
      );
    },
  },
};
</script>
<style scoped>
#reservationOffcanvas-schedule {
  min-width: 75% !important;
}
</style>
