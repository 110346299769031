import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

import HomeView from "../views/HomeView.vue";
import ReservationsView from "../views/ReservationsView.vue";
import SearchView from "../views/SearchView.vue";
import VehiclesView from "../views/VehiclesView.vue";
import SalesView from "../views/SalesView.vue";
import BillingView from "../views/BillingView.vue";
import DispatchView from "../views/DispatchView.vue";
import ScheduleView from "../views/ScheduleView.vue";
import MetricsView from "../views/MetricsView.vue";

import LoginView from "../views/LoginView.vue";
import EmailSentView from "../views/EmailSentView.vue";
import RedirectView from "../views/RedirectView.vue";
import AuthenticateView from "../views/AuthenticateView.vue";

import ErrorView from "../views/ErrorView.vue";
import PageNotFoundView from "../views/PageNotFoundView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home",
      authenticated: true,
    },
  },
  {
    path: "/vehicles",
    name: "vehicles",
    component: VehiclesView,
    meta: {
      title: "My Vehicles",
      authenticated: true,
    },
  },
  {
    path: "/search",
    name: "search",
    component: SearchView,
    meta: {
      title: "Search",
      authenticated: true,
    },
  },
  {
    path: "/reservations",
    name: "reservations",
    component: ReservationsView,
    meta: {
      title: "Reservations",
      authenticated: true,
    },
  },
  {
    path: "/dispatch",
    name: "dispatch",
    component: DispatchView,
    meta: {
      title: "Dispatch",
      authenticated: true,
    },
  },
  {
    path: "/sales",
    name: "sales",
    component: SalesView,
    meta: {
      title: "Sales",
      authenticated: true,
    },
  },
  {
    path: "/schedule",
    name: "schedule",
    component: ScheduleView,
    meta: {
      title: "Schedule",
      authenticated: true,
    },
  },
  {
    path: "/billing",
    name: "billing",
    component: BillingView,
    meta: {
      title: "Billing",
      authenticated: true,
    },
  },
  {
    path: "/metrics",
    name: "metrics",
    component: MetricsView,
    meta: {
      title: "Metrics",
      authenticated: true,
    },
  },
  {
    path: "/redirect/auth/:token",
    name: "redirect",
    component: RedirectView,
    meta: {
      title: "Redirecting...",
      authenticated: false,
    },
  },
  {
    path: "/get-started",
    name: "login",
    component: LoginView,
    meta: {
      title: "Get Started",
      authenticated: false,
    },
  },
  {
    path: "/get-started/confirmation",
    name: "emailSent",
    component: EmailSentView,
    meta: {
      title: "Check Your Email",
      authenticated: false,
    },
  },
  {
    path: "/get-started/authenticate",
    name: "authenticate",
    component: AuthenticateView,
    meta: {
      title: "Authenticating",
      authenticated: false,
    },
  },
  {
    path: "/error/:code",
    name: "error",
    component: ErrorView,
    meta: {
      title: "Page Error",
      authenticated: false,
    },
  },
  {
    path: "*",
    name: "404",
    component: PageNotFoundView,
    meta: {
      title: "Page Not Found",
      authenticated: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(from);
  console.log(to);
  if (to.meta.authenticated == true) {
    if (store.state.authenticated == false) {
      next({ path: "/get-started" });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title + " | Operations | Resla";
  });
});

export default router;
