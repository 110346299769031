import axios from "axios";
import router from "./router";
import store from "./store";

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Add credentials to header if auth parameter exists
    if (config.params.auth) {
      const token = store.state.token;
      if (token) {
        config.headers.Authorization = token;
      } else {
        // Redirect to login page or show a message
      }
      //Delete the internal param so it doesn't show up in url as ?auth=
      delete config.params.auth;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    console.log(error);
    // Do something with response error
    if (error.response.status === 401) {
      if (error.response.data.data.code == "access_denied") {
        router.push("/error/access_denied");
      } else if (error.response.data.data.code == "invalid_link") {
        router.push("/error/invalid_link");
      } else if (error.response.data.data.code == "force_sign_in") {
        store.commit("unsetAuthentication");
        router.push("/get-started");
      }
    } /* else if (error.response.status === 404) {
    router.push('/404')
  }*/ else {
      router.push("/error");
    }
    return Promise.reject(error);
  }
);
