<template>
  <div>
    <div class="container">
      <!-- Vehicles -->
      <div class="row g-0">
        <div class="col-12">
          <h1
            class="display-5 mt-5 mb-3 d-flex justify-content-center align-items-center"
          >
            <span class="highlighted me-auto">My Vehicles.</span>
            <button
              class="btn btn-primary mt-auto me-2"
              data-bs-toggle="offcanvas"
              data-bs-target="#vehiclesOffcanvas"
            >
              Filters
            </button>

            <input
              type="text"
              class="form-control w-auto mt-auto d-none d-md-block"
              placeholder="Search by Reservation, Customer, or Vehicle"
              v-model="searchQuery"
            />
          </h1>
          <label class="d-md-none">Search:</label>
          <input
            type="text"
            class="form-control w-100 d-md-none mb-3"
            placeholder="Search by Reservation, Customer, or Vehicle"
            v-model="searchQuery"
          />
          <p v-if="!vehicles.length">
            Looks like you don't have access to any vehicles! Contact your
            supervisor to get access.
          </p>
        </div>
      </div>
    </div>
    <div class="fluid-container">
      <!-- Reservations Table -->
      <div class="row g-0">
        <div class="col-12 mb-5" v-if="vehicles.length">
          <div
            class="table-responsive"
            style="min-height: 65vh !important; max-height: 65vh !important"
          >
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Vehicle</th>
                  <th>Current Reservation</th>
                  <th>Next Reservation</th>
                  <th>Reservation Notes</th>
                  <th>Vehicle Notes</th>
                  <th>Location</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody
                v-for="platform in ['ALL', 'TURO', 'HQ']"
                :key="'vehicles-row-platform-' + platform"
              >
                <tr style="background: rgb(0, 0, 255) !important">
                  <td
                    style="box-shadow: unset !important"
                    colspan="7"
                    class="align-middle"
                  >
                    <div class="d-flex">
                      <span
                        class="fw-bold my-auto"
                        style="color: white !important"
                        >Platform:
                        <span class="badge badge-white">{{ platform }}</span>
                      </span>
                      <button
                        class="btn btn-white mt-0 mb-0 fw-bold my-auto ms-auto"
                        data-bs-toggle="collapse"
                        :data-bs-target="
                          '#vehicles-tbody-' +
                          $store.state.region +
                          '-' +
                          platform
                        "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          fill="black"
                        >
                          <path
                            d="M13.9999 4.99989L5.00001 4.99976L4.99998 6.99976L11.9999 6.99986L12 14.5859H6.5858L13 21.0001L19.4142 14.5859L14 14.5859L13.9999 4.99989Z"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr
                  v-for="vehicle in sortedVehicles.filter((v) => {
                    return v.platform == platform;
                  })"
                  :key="'vehicle-' + vehicle.vin"
                  :id="'vehicles-tbody-' + $store.state.region + '-' + platform"
                >
                  <td class="border align-middle">
                    <div class="card-body">
                      <div class="vehicle-card d-flex align-items-center">
                        <img
                          :src="getVehicleImage(vehicle)"
                          class="rounded me-2"
                          alt="Vehicle Image"
                          style="height: 5vh"
                        />
                        <div class="d-flex flex-grow-1 align-items-center">
                          <span class="align-items-center flex-grow-1">
                            <h5
                              class="mb-0 clickable"
                              @click="copyToClipboard(vehicle.vin)"
                            >
                              {{ vehicle.model }} ({{ vehicle.vin.slice(-6) }})
                            </h5>
                            <p class="mt-1 mb-0">
                              <span class="badge badge-tertiary me-1">
                                {{ vehicle.platform }}
                              </span>
                              <span
                                class="badge badge-secondary me-1 clickable"
                                @click="copyToClipboard(vehicle.license_plate)"
                              >
                                {{ vehicle.license_plate }}
                              </span>
                              <span class="badge badge-tertiary me-1">
                                {{ vehicle.year }}
                              </span>
                              <span class="badge badge-tertiary me-1">
                                <span
                                  class="color-dot"
                                  :style="{
                                    'background-color':
                                      vehicle.color.toLowerCase() +
                                      ' !important',
                                  }"
                                ></span>
                              </span>
                              <span class="badge me-1">
                                {{ getRegionPrefix(vehicle.region_id) }}
                              </span>
                              <span class="badge badge-tertiary">
                                <!-- Status Text -->
                                {{ vehicle.status }}
                              </span>
                            </p>
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td
                    class="border"
                    style="min-width: 35% !important"
                    v-if="getCurrentReservation(vehicle).current"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center w-100"
                    >
                      <div class="flex-grow-1">
                        {{
                          getCurrentReservation(vehicle).current
                            .customer_first_name
                        }}
                        {{
                          getCurrentReservation(vehicle).current
                            .customer_last_name
                        }}
                        <br />
                        {{ getCurrentReservation(vehicle).current.platform_id }}
                        <span
                          class="badge"
                          :class="{
                            'badge-red':
                              getCurrentReservation(vehicle).current
                                .payment_balance > 0,
                            'badge-tertiary':
                              getCurrentReservation(vehicle).current
                                .payment_balance <= 0,
                          }"
                          >{{
                            new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(
                              getCurrentReservation(vehicle).current
                                .payment_balance
                            )
                          }}</span
                        >
                        <br />
                        {{
                          new Date(
                            getCurrentReservation(vehicle).current.pickup_at
                          ).toLocaleDateString("en-US", {
                            timeZone:
                              getCurrentReservation(vehicle).current
                                .pickup_timezone,
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                        }}
                        →
                        {{
                          new Date(
                            getCurrentReservation(vehicle).current.return_at
                          ).toLocaleDateString("en-US", {
                            timeZone:
                              getCurrentReservation(vehicle).current
                                .return_timezone,
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                        }}<br />
                        <!-- Payment Balance -->
                        <span
                          :class="{
                            highlighted:
                              getCurrentReservation(vehicle).current
                                .payment_balance == 0,
                          }"
                          title="Balance Owing"
                        >
                          <svg
                            style="height: 1.25rem"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            title="Payment Balance"
                          >
                            <path
                              d="M12.0049 22.0027C6.48204 22.0027 2.00488 17.5256 2.00488 12.0027C2.00488 6.4799 6.48204 2.00275 12.0049 2.00275C17.5277 2.00275 22.0049 6.4799 22.0049 12.0027C22.0049 17.5256 17.5277 22.0027 12.0049 22.0027ZM12.0049 20.0027C16.4232 20.0027 20.0049 16.421 20.0049 12.0027C20.0049 7.58447 16.4232 4.00275 12.0049 4.00275C7.5866 4.00275 4.00488 7.58447 4.00488 12.0027C4.00488 16.421 7.5866 20.0027 12.0049 20.0027ZM8.50488 14.0027H14.0049C14.281 14.0027 14.5049 13.7789 14.5049 13.5027C14.5049 13.2266 14.281 13.0027 14.0049 13.0027H10.0049C8.62417 13.0027 7.50488 11.8835 7.50488 10.5027C7.50488 9.12203 8.62417 8.00275 10.0049 8.00275H11.0049V6.00275H13.0049V8.00275H15.5049V10.0027H10.0049C9.72874 10.0027 9.50488 10.2266 9.50488 10.5027C9.50488 10.7789 9.72874 11.0027 10.0049 11.0027H14.0049C15.3856 11.0027 16.5049 12.122 16.5049 13.5027C16.5049 14.8835 15.3856 16.0027 14.0049 16.0027H13.0049V18.0027H11.0049V16.0027H8.50488V14.0027Z"
                            ></path>
                          </svg>
                        </span>
                        <!-- License -->
                        <span
                          :class="{
                            highlighted:
                              getCurrentReservation(vehicle).current
                                .confirm_license == true,
                          }"
                          title="Confirm License"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            style="height: 1.25rem"
                            fill="currentColor"
                          >
                            <path
                              d="M3 6H21V18H3V6ZM2 4C1.44772 4 1 4.44772 1 5V19C1 19.5523 1.44772 20 2 20H22C22.5523 20 23 19.5523 23 19V5C23 4.44772 22.5523 4 22 4H2ZM13 8H19V10H13V8ZM18 12H13V14H18V12ZM10.5 10C10.5 11.3807 9.38071 12.5 8 12.5C6.61929 12.5 5.5 11.3807 5.5 10C5.5 8.61929 6.61929 7.5 8 7.5C9.38071 7.5 10.5 8.61929 10.5 10ZM8 13.5C6.067 13.5 4.5 15.067 4.5 17H11.5C11.5 15.067 9.933 13.5 8 13.5Z"
                            ></path>
                          </svg>
                        </span>
                        <!-- Insurance-->
                        <span
                          :class="{
                            highlighted:
                              getCurrentReservation(vehicle).current
                                .confirm_insurance == true,
                          }"
                          title="Confirm Insurance"
                        >
                          <svg
                            style="height: 1.25rem"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <path
                              d="M14 9V4H5V20H11.0563C11.3838 20.4171 11.7803 20.7847 12.236 21.0848L13.626 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.4487 2 4.00221 2H14.9968L21 8V9H14ZM12 11H21V16.949C21 17.9397 20.4987 18.8648 19.6641 19.4144L16.5 21.4978L13.3359 19.4144C12.5013 18.8648 12 17.9397 12 16.949V11ZM14 16.949C14 17.2652 14.1616 17.5634 14.4358 17.744L16.5 19.1032L18.5642 17.744C18.8384 17.5634 19 17.2652 19 16.949V13H14V16.949Z"
                            ></path>
                          </svg>
                        </span>
                        <!-- Pickup Photos -->
                        <span
                          :class="{
                            highlighted:
                              getCurrentReservation(vehicle).current
                                .pickup_photos == true,
                          }"
                          title="Pickup Photos"
                        >
                          <svg
                            style="height: 1.25rem"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            fill="currentColor"
                          >
                            <path
                              d="M9.82843 5L7.82843 7H4V19H20V7H16.1716L14.1716 5H9.82843ZM9 3H15L17 5H21C21.5523 5 22 5.44772 22 6V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V6C2 5.44772 2.44772 5 3 5H7L9 3ZM12 18C8.96243 18 6.5 15.5376 6.5 12.5C6.5 9.46243 8.96243 7 12 7C15.0376 7 17.5 9.46243 17.5 12.5C17.5 15.5376 15.0376 18 12 18ZM12 16C13.933 16 15.5 14.433 15.5 12.5C15.5 10.567 13.933 9 12 9C10.067 9 8.5 10.567 8.5 12.5C8.5 14.433 10.067 16 12 16Z"
                            ></path>
                          </svg>
                        </span>
                        <!-- Upload Pickup Photos -->
                        <span
                          :class="{
                            highlighted:
                              getCurrentReservation(vehicle).current
                                .upload_pickup_photos == true ||
                              (getCurrentReservation(vehicle).current
                                .platform != 'TURO' &&
                                getCurrentReservation(vehicle).current
                                  .pickup_photos == true),
                          }"
                          title="Upload Pickup Photos"
                        >
                          <svg
                            style="height: 1.25rem"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <path
                              d="M4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19ZM13 9V16H11V9H6L12 3L18 9H13Z"
                            ></path>
                          </svg>
                        </span>
                        <!-- Return Photos -->
                        <span
                          :class="{
                            highlighted:
                              getCurrentReservation(vehicle).current
                                .return_photos == true,
                          }"
                          title="Return Photos"
                        >
                          <svg
                            style="height: 1.25rem"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            fill="currentColor"
                          >
                            <path
                              d="M9.82843 5L7.82843 7H4V19H20V7H16.1716L14.1716 5H9.82843ZM9 3H15L17 5H21C21.5523 5 22 5.44772 22 6V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V6C2 5.44772 2.44772 5 3 5H7L9 3ZM12 18C8.96243 18 6.5 15.5376 6.5 12.5C6.5 9.46243 8.96243 7 12 7C15.0376 7 17.5 9.46243 17.5 12.5C17.5 15.5376 15.0376 18 12 18ZM12 16C13.933 16 15.5 14.433 15.5 12.5C15.5 10.567 13.933 9 12 9C10.067 9 8.5 10.567 8.5 12.5C8.5 14.433 10.067 16 12 16Z"
                            ></path>
                          </svg>
                        </span>
                        <!-- Upload Return Photos -->
                        <span
                          :class="{
                            highlighted:
                              getCurrentReservation(vehicle).current
                                .upload_return_photos == true ||
                              (getCurrentReservation(vehicle).current
                                .platform != 'TURO' &&
                                getCurrentReservation(vehicle).current
                                  .return_photos == true),
                          }"
                          title="Upload Return Photos"
                        >
                          <svg
                            style="height: 1.25rem"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <path
                              d="M4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19ZM13 9V16H11V9H6L12 3L18 9H13Z"
                            ></path>
                          </svg>
                        </span>
                        <!-- Billing Complete -->
                        <span
                          :class="{
                            highlighted:
                              getCurrentReservation(vehicle).current
                                .billing_complete == true,
                          }"
                          title="Billing Complete"
                        >
                          <svg
                            style="height: 1.25rem"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <path
                              d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                      <button
                        @click="id = getCurrentReservation(vehicle).current.id"
                        tag="button"
                        class="btn btn-sm btn-primary ms-1"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#reservationOffcanvas-vehicles"
                      >
                        View
                      </button>
                    </div>
                  </td>

                  <td
                    class="text-center border"
                    style="min-width: 35% !important"
                    v-if="!getCurrentReservation(vehicle).current"
                  ></td>

                  <td
                    class="border"
                    style="min-width: 35% !important"
                    v-if="getCurrentReservation(vehicle).next"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center w-100"
                    >
                      <div class="flex-grow-1">
                        {{
                          getCurrentReservation(vehicle).next
                            .customer_first_name
                        }}
                        {{
                          getCurrentReservation(vehicle).next.customer_last_name
                        }}

                        <br />
                        {{ getCurrentReservation(vehicle).next.platform_id }}
                        <span class="badge badge-tertiary">{{
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(
                            getCurrentReservation(vehicle).next.payment_balance
                          )
                        }}</span>
                        <br />
                        {{
                          new Date(
                            getCurrentReservation(vehicle).next.pickup_at
                          ).toLocaleDateString("en-US", {
                            timeZone:
                              getCurrentReservation(vehicle).next
                                .pickup_timezone,
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                        }}
                        →
                        {{
                          new Date(
                            getCurrentReservation(vehicle).next.return_at
                          ).toLocaleDateString("en-US", {
                            timeZone:
                              getCurrentReservation(vehicle).next
                                .return_timezone,
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                        }}
                        <br />
                        <!-- Payment Balance -->
                        <span
                          :class="{
                            highlighted:
                              getCurrentReservation(vehicle).next
                                .payment_balance == 0,
                          }"
                        >
                          <svg
                            style="height: 1.25rem"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            title="Payment Balance"
                          >
                            <path
                              d="M12.0049 22.0027C6.48204 22.0027 2.00488 17.5256 2.00488 12.0027C2.00488 6.4799 6.48204 2.00275 12.0049 2.00275C17.5277 2.00275 22.0049 6.4799 22.0049 12.0027C22.0049 17.5256 17.5277 22.0027 12.0049 22.0027ZM12.0049 20.0027C16.4232 20.0027 20.0049 16.421 20.0049 12.0027C20.0049 7.58447 16.4232 4.00275 12.0049 4.00275C7.5866 4.00275 4.00488 7.58447 4.00488 12.0027C4.00488 16.421 7.5866 20.0027 12.0049 20.0027ZM8.50488 14.0027H14.0049C14.281 14.0027 14.5049 13.7789 14.5049 13.5027C14.5049 13.2266 14.281 13.0027 14.0049 13.0027H10.0049C8.62417 13.0027 7.50488 11.8835 7.50488 10.5027C7.50488 9.12203 8.62417 8.00275 10.0049 8.00275H11.0049V6.00275H13.0049V8.00275H15.5049V10.0027H10.0049C9.72874 10.0027 9.50488 10.2266 9.50488 10.5027C9.50488 10.7789 9.72874 11.0027 10.0049 11.0027H14.0049C15.3856 11.0027 16.5049 12.122 16.5049 13.5027C16.5049 14.8835 15.3856 16.0027 14.0049 16.0027H13.0049V18.0027H11.0049V16.0027H8.50488V14.0027Z"
                            ></path>
                          </svg>
                        </span>
                        <!-- License -->
                        <span
                          :class="{
                            highlighted:
                              getCurrentReservation(vehicle).next
                                .confirm_license == true,
                          }"
                          title="Confirm License"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            style="height: 1.25rem"
                            fill="currentColor"
                          >
                            <path
                              d="M3 6H21V18H3V6ZM2 4C1.44772 4 1 4.44772 1 5V19C1 19.5523 1.44772 20 2 20H22C22.5523 20 23 19.5523 23 19V5C23 4.44772 22.5523 4 22 4H2ZM13 8H19V10H13V8ZM18 12H13V14H18V12ZM10.5 10C10.5 11.3807 9.38071 12.5 8 12.5C6.61929 12.5 5.5 11.3807 5.5 10C5.5 8.61929 6.61929 7.5 8 7.5C9.38071 7.5 10.5 8.61929 10.5 10ZM8 13.5C6.067 13.5 4.5 15.067 4.5 17H11.5C11.5 15.067 9.933 13.5 8 13.5Z"
                            ></path>
                          </svg>
                        </span>
                        <!-- Insurance-->
                        <span
                          :class="{
                            highlighted:
                              getCurrentReservation(vehicle).next
                                .confirm_insurance == true,
                          }"
                          title="Confirm Insurance"
                        >
                          <svg
                            style="height: 1.25rem"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <path
                              d="M14 9V4H5V20H11.0563C11.3838 20.4171 11.7803 20.7847 12.236 21.0848L13.626 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.4487 2 4.00221 2H14.9968L21 8V9H14ZM12 11H21V16.949C21 17.9397 20.4987 18.8648 19.6641 19.4144L16.5 21.4978L13.3359 19.4144C12.5013 18.8648 12 17.9397 12 16.949V11ZM14 16.949C14 17.2652 14.1616 17.5634 14.4358 17.744L16.5 19.1032L18.5642 17.744C18.8384 17.5634 19 17.2652 19 16.949V13H14V16.949Z"
                            ></path>
                          </svg>
                        </span>
                        <!-- Pickup Photos -->
                        <span
                          :class="{
                            highlighted:
                              getCurrentReservation(vehicle).next
                                .pickup_photos == true,
                          }"
                          title="Pickup Photos"
                        >
                          <svg
                            style="height: 1.25rem"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            fill="currentColor"
                          >
                            <path
                              d="M9.82843 5L7.82843 7H4V19H20V7H16.1716L14.1716 5H9.82843ZM9 3H15L17 5H21C21.5523 5 22 5.44772 22 6V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V6C2 5.44772 2.44772 5 3 5H7L9 3ZM12 18C8.96243 18 6.5 15.5376 6.5 12.5C6.5 9.46243 8.96243 7 12 7C15.0376 7 17.5 9.46243 17.5 12.5C17.5 15.5376 15.0376 18 12 18ZM12 16C13.933 16 15.5 14.433 15.5 12.5C15.5 10.567 13.933 9 12 9C10.067 9 8.5 10.567 8.5 12.5C8.5 14.433 10.067 16 12 16Z"
                            ></path>
                          </svg>
                        </span>
                        <!-- Upload Pickup Photos -->
                        <span
                          :class="{
                            highlighted:
                              getCurrentReservation(vehicle).next
                                .upload_pickup_photos == true ||
                              (getCurrentReservation(vehicle).next.platform !=
                                'TURO' &&
                                getCurrentReservation(vehicle).next
                                  .pickup_photos == true),
                          }"
                          title="Upload Pickup Photos"
                        >
                          <svg
                            style="height: 1.25rem"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <path
                              d="M4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19ZM13 9V16H11V9H6L12 3L18 9H13Z"
                            ></path>
                          </svg>
                        </span>
                        <!-- Return Photos -->
                        <span
                          :class="{
                            highlighted:
                              getCurrentReservation(vehicle).next
                                .return_photos == true,
                          }"
                          title="Return Photos"
                        >
                          <svg
                            style="height: 1.25rem"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            fill="currentColor"
                          >
                            <path
                              d="M9.82843 5L7.82843 7H4V19H20V7H16.1716L14.1716 5H9.82843ZM9 3H15L17 5H21C21.5523 5 22 5.44772 22 6V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V6C2 5.44772 2.44772 5 3 5H7L9 3ZM12 18C8.96243 18 6.5 15.5376 6.5 12.5C6.5 9.46243 8.96243 7 12 7C15.0376 7 17.5 9.46243 17.5 12.5C17.5 15.5376 15.0376 18 12 18ZM12 16C13.933 16 15.5 14.433 15.5 12.5C15.5 10.567 13.933 9 12 9C10.067 9 8.5 10.567 8.5 12.5C8.5 14.433 10.067 16 12 16Z"
                            ></path>
                          </svg>
                        </span>
                        <!-- Upload Return Photos -->
                        <span
                          :class="{
                            highlighted:
                              getCurrentReservation(vehicle).next
                                .upload_return_photos == true ||
                              (getCurrentReservation(vehicle).next.platform !=
                                'TURO' &&
                                getCurrentReservation(vehicle).next
                                  .return_photos == true),
                          }"
                          title="Upload Return Photos"
                        >
                          <svg
                            style="height: 1.25rem"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <path
                              d="M4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19ZM13 9V16H11V9H6L12 3L18 9H13Z"
                            ></path>
                          </svg>
                        </span>
                        <!-- Billing Complete -->
                        <span
                          :class="{
                            highlighted:
                              getCurrentReservation(vehicle).next
                                .billing_complete == true,
                          }"
                          title="Billing Complete"
                        >
                          <svg
                            style="height: 1.25rem"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                          >
                            <path
                              d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                      <button
                        @click="id = getCurrentReservation(vehicle).next.id"
                        tag="button"
                        class="btn btn-sm btn-primary ms-1"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#reservationOffcanvas-vehicles"
                      >
                        View
                      </button>
                    </div>
                  </td>

                  <td
                    class="text-center border"
                    style="min-width: 35% !important"
                    v-if="!getCurrentReservation(vehicle).next"
                  ></td>

                  <td class="border align-middle" style="min-width: 250px">
                    <textarea
                      class="form-control"
                      @input="
                        onReservationNotesChange(
                          $event,
                          getCurrentReservation(vehicle).current.id
                        )
                      "
                      :value="getCurrentReservation(vehicle).current.notes"
                      v-if="getCurrentReservation(vehicle).current"
                    ></textarea>
                  </td>

                  <td class="border align-middle" style="min-width: 250px">
                    <textarea
                      class="form-control"
                      @input="onNotesChange($event, vehicle)"
                      :value="vehicle.notes"
                    ></textarea>
                  </td>

                  <td class="border align-middle" style="min-width: 250px">
                    <select
                      class="form-control form-select w-100"
                      :value="vehicle.location_id"
                      @change="onLocationChange($event, vehicle)"
                    >
                      <option
                        :value="location.id"
                        v-for="location in locations(vehicle)"
                        :key="
                          'location-select-' + vehicle.vin + '-' + location.id
                        "
                      >
                        {{ location.name }}
                      </option>
                    </select>
                  </td>

                  <td class="align-middle border">
                    <div
                      class="btn-group btn-group-sm align-middle"
                      role="group"
                      aria-label="Button group with nested dropdown"
                    >
                      <div class="btn-group" role="group">
                        <button
                          type="button"
                          class="btn btn-primary btn-sm dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Action
                        </button>
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              class="dropdown-item"
                              @click="updateStatus(vehicle, 'Ready')"
                              >Mark as Ready</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              @click="updateStatus(vehicle, 'On Rent')"
                              >Mark as On Rent</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              @click="updateStatus(vehicle, 'Dirty')"
                              >Mark as Dirty</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              @click="updateStatus(vehicle, 'Damaged')"
                              >Mark as Damaged</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              @click="updateStatus(vehicle, 'Repairing')"
                              >Mark as Out for Repair</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              @click="updateStatus(vehicle, 'Unavailable')"
                              >Mark as Unavailable</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              @click="updateStatus(vehicle, 'Employee')"
                              v-if="vehicle.status != 'Employee'"
                              >Transfer to Employee</a
                            >
                            <a
                              class="dropdown-item"
                              @click="updateStatus(vehicle, 'Dirty')"
                              v-else
                              >Transfer to Fleet</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td
                    :colspan="7"
                    :id="
                      'vehicles-tbody-' + $store.state.region + '-' + platform
                    "
                    v-if="
                      sortedVehicles.filter((v) => {
                        return v.platform == platform;
                      }) == 0 ||
                      !sortedVehicles.filter((v) => {
                        return v.platform == platform;
                      })
                    "
                  >
                    No results found.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Reservation View -->
      <div
        class="offcanvas offcanvas-start"
        tabindex="-1"
        id="reservationOffcanvas-vehicles"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header align-middle">
          <h3 class="highlighted my-0 mt-0 fw-bold">View Reservation</h3>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            Close
          </button>
        </div>
        <div class="offcanvas-body">
          <ReservationOffcanvas :id="id"></ReservationOffcanvas>
        </div>
      </div>
      <!-- Filters -->
      <div
        class="offcanvas offcanvas-start"
        tabindex="-1"
        id="vehiclesOffcanvas"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header align-middle">
          <h3 class="highlighted my-0 mt-0 fw-bold">Filters</h3>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            Close
          </button>
        </div>
        <div class="offcanvas-body">
          <h6 class="mt-3">Search By</h6>
          <!-- Search by Reservation, Customer, or Vehicle -->
          <label class="mt-3">Keywords</label>
          <input
            type="text"
            class="form-control w-100 mb-3"
            placeholder="Reservation, Customer, or Vehicle"
            v-model="searchQuery"
          />

          <div class="mt-3 mb-3"></div>
          <h6 class="mt-5">Sort By</h6>
          <label class="mt-3">Attribute</label>
          <!-- Sort By -->
          <select class="form-control form-select w-100" v-model="sorted">
            <option value="vin">Vin</option>
            <option value="model">Model</option>
            <option value="status">Status</option>
            <option value="region">Region</option>
            <option value="license_plate">License Plate</option>
            <option value="location_id">Location</option>
            <option value="region_id">Region</option>
            <option value="color">Color</option>
          </select>
          <label class="mt-3">Order</label>
          <!-- Sort By -->
          <select class="form-control form-select w-100 mb-3" v-model="order">
            <option value="desc">Descending</option>
            <option value="asc">Ascending</option>
          </select>

          <h6 class="mt-5">Filter By</h6>
          <!-- Filter by Status -->
          <label class="mt-3">Filter by Vehicle Status</label>
          <select class="form-control form-select w-100" v-model="status">
            <option value="">All</option>
            <option value="Dirty">Dirty</option>
            <option value="Return Photos">Return Photos</option>
            <option value="Cleaning">Cleaning</option>
            <option value="Pickup Photos">Pickup Photos</option>
            <option value="Ready">Ready</option>
            <option value="On Rent">On Rent</option>
            <option value="Damaged">Damaged</option>
            <option value="Repairing">Out for Repair</option>
            <option value="Unavailable">Unavailable</option>
            <option value="Employee">Employee</option>
          </select>
          <!-- Filter by Location -->
          <label class="mt-3">Filter by Location</label>
          <select
            class="form-control form-select w-100 mb-5"
            v-model="location"
          >
            <option value="">All</option>
            <option
              :value="location.id"
              v-for="location of $store.state.user.locations.filter((l) => {
                return l.region_id == $store.state.region;
              })"
              :key="'filter-vehicles-location-' + location.id"
            >
              {{ location.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReservationOffcanvas from "@/components/offcanvas/ReservationOffcanvas.vue";

export default {
  name: "VehiclesView",
  data: function () {
    return {
      searchQuery: "",
      status: "",
      sorted: "vin",
      order: "desc",
      region: "",
      location: "",
      id: "", //Reservation offcanvas
    };
  },
  components: {
    ReservationOffcanvas,
  },
  created: function () {
    this.debouncedUpdateNotes = this.lodash.debounce(this.updateNotes, 4000);
    this.debouncedUpdateReservationNotes = this.lodash.debounce(
      this.updateReservationNotes,
      4000
    );
    this.debouncedUpdateLocation = this.lodash.debounce(
      this.updateLocation,
      200
    );
  },
  methods: {
    redirect: function (reservation, index) {
      console.log(reservation[index]);
      window.open("/reservation/" + reservation[index].id.toString(), "_blank");
    },
    getVehicleImage(vehicle) {
      return (
        this.$store.state.user.models.find((r) => r.name === vehicle.model)
          ?.public_image_link || "default-image-link"
      );
    },
    getRegionPrefix(regionId) {
      return (
        this.$store.state.user.regions.find((r) => r.id === regionId)?.prefix ||
        ""
      );
    },
    onNotesChange: function (event, vehicle) {
      const newNotes = event.target.value;
      this.debouncedUpdateNotes(vehicle, newNotes);
    },
    onReservationNotesChange: function (event, id) {
      const newNotes = event.target.value;
      this.debouncedUpdateReservationNotes(id, newNotes);
    },
    onLocationChange: function (event, vehicle) {
      const location = event.target.value;
      this.debouncedUpdateLocation(vehicle, location);
    },
    updateNotes: function (vehicle, notes) {
      this.$axios
        .post(
          this.$store.state.root_url + "/vehicle/notes",
          {
            vin: vehicle.vin,
            notes: notes,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateReservationNotes: function (id, notes) {
      this.$axios
        .post(
          this.$store.state.root_url + "/reservation/notes",
          {
            id: id,
            notes: notes,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateLocation: function (vehicle, location) {
      this.$axios
        .post(
          this.$store.state.root_url + "/vehicle/location",
          {
            vin: vehicle.vin,
            location: location,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateStatus: function (vehicle, status) {
      this.$axios
        .post(
          this.$store.state.root_url + "/vehicle/status",
          {
            vin: vehicle.vin,
            status: status,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    locations(vehicle) {
      return this.$store.state.user.locations.filter(
        (location) =>
          location.active === true && location.region_id == vehicle.region_id
      );
    },
    getCurrentReservation(vehicle) {
      const now = new Date(); // Represents the current time
      const twoWeeksAgo = new Date(now.getTime() - 14 * 24 * 60 * 60 * 1000); // 2 weeks ago from current time

      // Filter out cancelled and returned reservations and sort by pickup time
      let vehicleReservations = this.reservations
        .filter(
          (reservation) =>
            reservation.vehicle_vin === vehicle.vin &&
            reservation.status !== "Canceled" &&
            reservation.status !== "Returned"
        )
        // eslint-disable-next-line
        .sort((a, b) => new Date(a.pickup_at) - new Date(b.pickup_at));

      // Find all "On Rent" reservations
      const onRentReservations = vehicleReservations.filter(
        (reservation) => reservation.status === "On Rent"
      );

      // Determine the current reservation
      let currentReservation = null;
      if (onRentReservations.length === 1) {
        currentReservation = onRentReservations[0];
      } else if (onRentReservations.length > 1) {
        // Sort by oldest return date and select the first
        currentReservation = onRentReservations.sort(
          (a, b) => new Date(a.dropoff_at) - new Date(b.dropoff_at)
        )[0];
      }

      // Remove the current reservation from the list if it exists
      if (currentReservation) {
        vehicleReservations = vehicleReservations.filter(
          (reservation) => reservation !== currentReservation
        );
      }

      // Sort remaining reservations by the closest upcoming date
      vehicleReservations = vehicleReservations
        .filter(
          (reservation) =>
            reservation.status === "Pending" || reservation.status === "On Rent"
        )
        // eslint-disable-next-line
        .sort((a, b) => new Date(a.pickup_at) - now);

      // Find the next reservation
      const nextReservation =
        vehicleReservations.length > 0 ? vehicleReservations[0] : null;

      // Find the most recent reservation that has returned within the last two weeks
      let recentReservation =
        this.reservations
          .filter(
            (reservation) =>
              reservation.vehicle_vin === vehicle.vin &&
              reservation.status === "Returned" &&
              new Date(reservation.return_at) >= twoWeeksAgo &&
              new Date(reservation.return_at) <= now
          )
          // Sort them by dropoff date, most recent first
          .sort((a, b) => new Date(b.return_at) - new Date(a.return_at))[0] ||
        null; // Take the most recent or null if none

      // Return the entire reservation objects or null
      return {
        current: currentReservation || null,
        next: nextReservation,
        recent: recentReservation,
      };
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          // Optional: Display a message or perform an action to inform the user that the text has been copied
          console.log("Copied to clipboard:", text);
        })
        .catch((err) => {
          console.error("Error in copying text: ", err);
        });
    },
  },
  computed: {
    vehicles() {
      return this.$store.state.user.vehicles;
    },
    reservations() {
      return this.$store.state.user.reservations;
    },
    sortedVehicles: function () {
      //Make it reactive to reservations changes
      // eslint-disable-next-line
      const reservations = this.reservations;

      let filteredVehicles = this.vehicles;

      // Step 1a: Filter based on status
      if (this.status !== "") {
        filteredVehicles = this.vehicles.filter((vehicle) => {
          return vehicle.status === this.status;
        });
      }
      // Step 1b: Filter based on region
      if (this.region !== "") {
        filteredVehicles = filteredVehicles.filter((vehicle) => {
          return vehicle.region_id === this.region;
        });
      }
      // Step 1c: Filter based on location
      if (this.location !== "") {
        filteredVehicles = filteredVehicles.filter((vehicle) => {
          return vehicle.location_id === this.location;
        });
      }

      // Step 2: Apply search filter to the result
      const searchTerms = this.searchQuery.toLowerCase().trim().split(/\s+/);
      const vehicleMatches = filteredVehicles.filter((vehicle) => {
        const vin = vehicle.vin.toLowerCase();
        const model = vehicle.model.toLowerCase();
        const licensePlate = vehicle.license_plate
          ? vehicle.license_plate.toLowerCase()
          : "";

        return searchTerms.some(
          (term) =>
            vin.includes(term) ||
            model.includes(term) ||
            licensePlate.includes(term)
        );
      });

      const reservationVehicleVins = this.reservations
        .filter((reservation) => {
          return searchTerms.some((term) => {
            const firstName = reservation.customer_first_name.toLowerCase();
            const lastName = reservation.customer_last_name.toLowerCase();
            const id = reservation.id.toString().toLowerCase();
            const platformId = reservation.platform_id.toLowerCase();
            const phone = reservation.phone.toLowerCase();
            const vehicleModel = reservation.vehicle_model.toLowerCase();

            return (
              firstName.includes(term) ||
              lastName.includes(term) ||
              id.includes(term) ||
              platformId.includes(term) ||
              phone.includes(term) ||
              vehicleModel.includes(term)
            );
          });
        })
        .map((reservation) => reservation.vehicle_vin)
        .filter((vin) =>
          filteredVehicles.some((vehicle) => vehicle.vin === vin)
        );

      const combinedVehicleVins = [
        ...new Set([
          ...vehicleMatches.map((v) => v.vin),
          ...reservationVehicleVins,
        ]),
      ];

      return this.lodash.orderBy(
        this.vehicles.filter((vehicle) =>
          combinedVehicleVins.includes(vehicle.vin)
        ),
        [this.sorted],
        [this.order]
      );
    },
  },
};
</script>

<style scoped>
.vehicle-card {
  min-width: 250px !important;
}

.table-responsive {
  overflow-x: auto;
}

.reservation-indicator {
  /* Styles for indicating a reservation on a specific day */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

th,
td {
  white-space: nowrap !important; /* Prevent wrapping in table headers */
}
@media (max-width: 767.98px) {
  #reservationOffcanvas-vehicles {
    width: 100% !important;
  }
}
#reservationOffcanvas-vehicles {
  min-width: 75% !important;
}
thead th {
  position: sticky;
  top: 0;
  background-color: white; /* To ensure the header is not transparent */
  z-index: 1; /* To keep the header above other content */
}
.clickable {
  cursor: pointer;
}
.color-dot {
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  display: inline-block;
  border: black 1px solid;
  vertical-align: middle !important;
}
</style>
