<template>
  <div>
    <div class="container">
      <!-- Reservations Table -->
      <div class="row g-0">
        <div class="col-12">
          <h1
            class="display-5 mt-5 mb-3 d-flex justify-content-center align-items-center"
          >
            <span class="highlighted me-auto">Dispatch.</span>
            <select
              class="form-select w-25 mt-auto me-2"
              id="dateSelect"
              v-model="selected"
              @change="selectDate"
            >
              <option value="yesterday">Yesterday</option>
              <option value="today" selected>Today</option>
              <option value="tomorrow">Tomorrow</option>
            </select>
            <button
              class="btn btn-primary mt-auto me-2"
              data-bs-toggle="modal"
              data-bs-target="#unassigned-modal"
            >
              Assign
              <span class="badge badge-tertiary">{{
                reservationsByVehicle.unassigned.length
              }}</span>
            </button>
          </h1>
          <p v-if="!reservations.length">
            Looks like there aren't any reservations! Contact your supervisor if
            you believe this to be an error.
          </p>
        </div>
      </div>
    </div>
    <div class="container" style="min-height: 65vh !important">
      <!-- Pickups -->
      <h3 class="mt-3">Pickups</h3>
      <div class="row g-0">
        <div class="table-responsive">
          <table class="table mb-3">
            <thead>
              <tr>
                <th scope="col">Vehicle</th>
                <th scope="col">Schedule</th>
                <th scope="col" class="text-center"></th>
              </tr>
            </thead>
            <tbody v-if="_.isEmpty(reservationsByVehicle.pickups)">
              <tr>
                <td colspan="4" class="stripe">No pickups scheduled.</td>
              </tr>
            </tbody>
            <tbody v-if="!_.isEmpty(reservationsByVehicle.pickups)">
              <template
                v-for="(
                  groupedReservations, vehicle, index
                ) in reservationsByVehicle.pickups"
              >
                <tr
                  :key="'dispatch-vehicle-' + vehicle"
                  :class="{ stripe: index % 2 === 0 }"
                >
                  <td>
                    <div class="vehicle-card d-flex align-items-center">
                      <img
                        :src="
                          getVehicleImage(
                            $store.state.user.vehicles.find((v) => {
                              return vehicle == v.vin;
                            }).model
                          )
                        "
                        class="rounded me-2"
                        alt="Vehicle Image"
                        style="height: 5vh"
                      />
                      <div class="d-flex flex-grow-1 align-items-center">
                        <span class="align-items-center flex-grow-1">
                          <h5
                            class="mb-0 clickable"
                            @click="copyToClipboard(vehicle)"
                          >
                            {{
                              $store.state.user.vehicles.find(
                                (v) => v.vin === vehicle
                              )
                                ? $store.state.user.vehicles.find(
                                    (v) => v.vin === vehicle
                                  ).model
                                : ""
                            }}
                            <span>({{ vehicle.slice(-6) }})</span>
                          </h5>
                          <p class="mb-0 mt-0">
                            {{ getVehicleLocation(vehicle) }}
                          </p>
                          <p class="mb-0">
                            <span
                              class="badge badge-secondary me-1 clickable"
                              @click="
                                copyToClipboard(
                                  $store.state.user.vehicles.find(
                                    (v) => v.vin === vehicle
                                  )
                                    ? $store.state.user.vehicles.find(
                                        (v) => v.vin === vehicle
                                      ).license_plate
                                    : ''
                                )
                              "
                            >
                              {{
                                $store.state.user.vehicles.find(
                                  (v) => v.vin === vehicle
                                )
                                  ? $store.state.user.vehicles.find(
                                      (v) => v.vin === vehicle
                                    ).license_plate
                                  : ""
                              }}
                            </span>
                            <span class="badge">
                              {{
                                $store.state.user.vehicles.find(
                                  (v) => v.vin === vehicle
                                )
                                  ? getRegionPrefix(
                                      $store.state.user.vehicles.find(
                                        (v) => v.vin === vehicle
                                      ).region_id
                                    )
                                  : ""
                              }}
                            </span>
                            <span class="ms-1 badge badge-tertiary">{{
                              $store.state.user.vehicles.find(
                                (v) => v.vin === vehicle
                              )
                                ? $store.state.user.vehicles.find(
                                    (v) => v.vin === vehicle
                                  ).status
                                : ""
                            }}</span>
                          </p>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle">
                    <span
                      v-for="(leg, index) in formatLocation(
                        groupedReservations,
                        vehicle
                      )"
                      :key="'leg-' + index + '-region-' + $store.state.region"
                    >
                      <span v-if="index !== 0"> → </span>
                      <!-- Swap -->
                      <span
                        v-if="
                          $store.state.user.vehicles.find(
                            (v) => v.vin === vehicle
                          ).status == 'Repairing' ||
                          $store.state.user.vehicles.find(
                            (v) => v.vin === vehicle
                          ).status == 'Damaged' ||
                          $store.state.user.vehicles.find(
                            (v) => v.vin === vehicle
                          ).status == 'Unavailable' ||
                          $store.state.user.vehicles.find(
                            (v) => v.vin === vehicle
                          ).status == 'Employee'
                        "
                        class="badge badge-primary"
                        >Swap</span
                      >
                      <span
                        v-if="
                          $store.state.user.vehicles.find(
                            (v) => v.vin === vehicle
                          ).status == 'Repairing' ||
                          $store.state.user.vehicles.find(
                            (v) => v.vin === vehicle
                          ).status == 'Damaged' ||
                          $store.state.user.vehicles.find(
                            (v) => v.vin === vehicle
                          ).status == 'Unavailable' ||
                          $store.state.user.vehicles.find(
                            (v) => v.vin === vehicle
                          ).status == 'Employee'
                        "
                      >
                        →
                      </span>
                      <!-- Only pickup -->
                      <span
                        v-if="
                          leg.type === 'Pickup' &&
                          index === 0 &&
                          leg.location_id !== leg.location.hq_id
                        "
                        class="badge badge-primary"
                        >Transfer</span
                      >
                      <span
                        v-if="
                          leg.type === 'Pickup' &&
                          index === 0 &&
                          leg.location_id !== leg.location.hq_id
                        "
                      >
                        →
                      </span>
                      <!-- If not ready -->
                      <span
                        v-if="leg.photos == false && leg.type === 'Pickup'"
                        class="badge badge-primary"
                        >Photos</span
                      >
                      <span
                        v-if="leg.photos == false && leg.type === 'Return'"
                        class="badge badge-primary"
                        >Clean</span
                      >
                      <span v-if="leg.photos == false"> → </span>
                      <!-- Multi Leg -->
                      <span
                        v-if="
                          index > 0 &&
                          leg.location_id !==
                            formatLocation(groupedReservations, vehicle)[
                              index - 1
                            ].location.hq_id
                        "
                        class="badge badge-primary"
                        >Transfer</span
                      >
                      <span
                        v-if="
                          index > 0 &&
                          leg.location_id !==
                            formatLocation(groupedReservations, vehicle)[
                              index - 1
                            ].location.hq_id
                        "
                      >
                        →
                      </span>
                      <!-- Leg Info -->
                      <span class="badge badge-tertiary">{{
                        leg.type[0]
                      }}</span>
                      {{
                        leg.type === "Return"
                          ? leg.return_location
                          : leg.pickup_location
                      }}
                      {{
                        new Date(leg.datetime).toLocaleDateString("en-US", {
                          timeZone: leg.timezone,
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })
                      }}
                    </span>
                  </td>
                  <td class="text-end align-middle">
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#dispatch-row-' + vehicle"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        fill="rgba(255,255,255,1)"
                      >
                        <path
                          d="M13.9999 4.99989L5.00001 4.99976L4.99998 6.99976L11.9999 6.99986L12 14.5859H6.5858L13 21.0001L19.4142 14.5859L14 14.5859L13.9999 4.99989Z"
                        ></path>
                      </svg>
                    </button>
                  </td>
                </tr>
                <tr
                  :key="'dispatch-vehicle-expanded-' + vehicle"
                  :id="'dispatch-row-' + vehicle"
                  class="collapse pe-0 ps-0"
                >
                  <td colspan="4" class="pe-0 ps-0">
                    <div :id="'dispatch-row-' + vehicle" class="collapse">
                      <div class="table-responsive">
                        <table class="table table-striped mb-3">
                          <thead>
                            <tr>
                              <th scope="col">ID</th>
                              <th scope="col">Location</th>
                              <th scope="col">Pickup</th>
                              <th scope="col">Return</th>
                              <th scope="col">Status</th>
                              <th scope="col">View</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="reservation in _.orderBy(
                                groupedReservations,
                                'pickup_at',
                                'desc'
                              )"
                              :key="
                                'dispatch-reservation-row-' + reservation.id
                              "
                            >
                              <td class="align-middle">
                                {{ reservation.platform_id }}
                              </td>
                              <td class="align-middle">
                                {{ reservation.pickup_location }} →
                                {{ reservation.return_location }}
                              </td>
                              <td class="align-middle">
                                {{
                                  new Date(
                                    reservation.pickup_at
                                  ).toLocaleDateString("en-US", {
                                    timeZone: reservation.pickup_timezone,
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })
                                }}
                              </td>
                              <td class="align-middle">
                                {{
                                  new Date(
                                    reservation.return_at
                                  ).toLocaleDateString("en-US", {
                                    timeZone: reservation.pickup_timezone,
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })
                                }}
                              </td>
                              <td class="align-middle">
                                <span class="badge badge-tertiary">{{
                                  reservation.status
                                }}</span>
                              </td>
                              <td class="text-center align-middle">
                                <button
                                  type="button"
                                  class="btn btn-primary btn-sm"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="
                                  #reservationOffcanvas
                                "
                                  @click="id = reservation.id"
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr></template
              >
            </tbody>
          </table>
        </div>
      </div>
      <h3 class="mt-3">Returns</h3>
      <div class="row g-0">
        <div class="table-responsive">
          <table class="table mb-5">
            <thead>
              <tr>
                <th scope="col">Vehicle</th>
                <th scope="col">Schedule</th>
                <th scope="col" class="text-center"></th>
              </tr>
            </thead>
            <tbody v-if="_.isEmpty(reservationsByVehicle.returns)">
              <tr>
                <td colspan="4" class="stripe">No returns scheduled.</td>
              </tr>
            </tbody>
            <tbody v-if="!_.isEmpty(reservationsByVehicle.returns)">
              <template
                v-for="(
                  groupedReservations, vehicle, index
                ) in reservationsByVehicle.returns"
              >
                <tr
                  :key="'dispatch-vehicle-' + vehicle"
                  :class="{ stripe: index % 2 === 0 }"
                >
                  <td>
                    <div class="vehicle-card d-flex align-items-center">
                      <img
                        :src="
                          getVehicleImage(
                            $store.state.user.vehicles.find((v) => {
                              return vehicle == v.vin;
                            }).model
                          )
                        "
                        class="rounded me-2"
                        alt="Vehicle Image"
                        style="height: 5vh"
                      />
                      <div class="d-flex flex-grow-1 align-items-center">
                        <span class="align-items-center flex-grow-1">
                          <h5
                            class="mb-0 clickable"
                            @click="copyToClipboard(vehicle)"
                          >
                            {{
                              $store.state.user.vehicles.find(
                                (v) => v.vin === vehicle
                              )
                                ? $store.state.user.vehicles.find(
                                    (v) => v.vin === vehicle
                                  ).model
                                : ""
                            }}
                            <span>({{ vehicle.slice(-6) }})</span>
                          </h5>
                          <p class="mb-0 mt-0">
                            {{ getVehicleLocation(vehicle) }}
                          </p>
                          <p class="mb-0">
                            <span
                              class="badge badge-secondary me-1 clickable"
                              @click="
                                copyToClipboard(
                                  $store.state.user.vehicles.find(
                                    (v) => v.vin === vehicle
                                  )
                                    ? $store.state.user.vehicles.find(
                                        (v) => v.vin === vehicle
                                      ).license_plate
                                    : ''
                                )
                              "
                            >
                              {{
                                $store.state.user.vehicles.find(
                                  (v) => v.vin === vehicle
                                )
                                  ? $store.state.user.vehicles.find(
                                      (v) => v.vin === vehicle
                                    ).license_plate
                                  : ""
                              }}
                            </span>
                            <span class="badge">
                              {{
                                $store.state.user.vehicles.find(
                                  (v) => v.vin === vehicle
                                )
                                  ? getRegionPrefix(
                                      $store.state.user.vehicles.find(
                                        (v) => v.vin === vehicle
                                      ).region_id
                                    )
                                  : ""
                              }}
                            </span>
                            <span class="ms-1 badge badge-tertiary">{{
                              $store.state.user.vehicles.find(
                                (v) => v.vin === vehicle
                              )
                                ? $store.state.user.vehicles.find(
                                    (v) => v.vin === vehicle
                                  ).status
                                : ""
                            }}</span>
                          </p>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle">
                    <span
                      v-for="(leg, index) in formatLocation(
                        groupedReservations,
                        vehicle
                      )"
                      :key="'leg-' + index + '-region-' + $store.state.region"
                    >
                      <span v-if="index !== 0"> → </span>
                      <!-- Only pickup -->
                      <span
                        v-if="
                          leg.type === 'Pickup' &&
                          index === 0 &&
                          leg.location_id !== leg.location.hq_id
                        "
                        class="badge badge-primary"
                        >Transfer</span
                      >
                      <span
                        v-if="
                          leg.type === 'Pickup' &&
                          index === 0 &&
                          leg.location_id !== leg.location.hq_id
                        "
                      >
                        →
                      </span>
                      <!-- Multi Leg -->
                      <span
                        v-if="
                          index > 0 &&
                          leg.location_id !==
                            formatLocation(groupedReservations, vehicle)[
                              index - 1
                            ].location.hq_id
                        "
                        class="badge badge-primary"
                        >Transfer</span
                      >
                      <span
                        v-if="
                          index > 0 &&
                          leg.location_id !==
                            formatLocation(groupedReservations, vehicle)[
                              index - 1
                            ].location.hq_id
                        "
                      >
                        →
                      </span>
                      <!-- Leg Info -->
                      <span class="badge badge-tertiary">{{
                        leg.type[0]
                      }}</span>
                      {{
                        leg.type === "Return"
                          ? leg.return_location
                          : leg.pickup_location
                      }}
                      {{
                        new Date(leg.datetime).toLocaleDateString("en-US", {
                          timeZone: leg.timezone,
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })
                      }}
                      <span v-if="leg.photos == false"> → </span>
                      <span
                        v-if="leg.photos == false"
                        class="badge badge-primary"
                        >Clean</span
                      >
                    </span>
                  </td>
                  <td class="text-end align-middle">
                    <button
                      type="button"
                      class="btn btn-primary btn-sm"
                      data-bs-toggle="collapse"
                      :data-bs-target="'#dispatch-row-' + vehicle"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        fill="rgba(255,255,255,1)"
                      >
                        <path
                          d="M13.9999 4.99989L5.00001 4.99976L4.99998 6.99976L11.9999 6.99986L12 14.5859H6.5858L13 21.0001L19.4142 14.5859L14 14.5859L13.9999 4.99989Z"
                        ></path>
                      </svg>
                    </button>
                  </td>
                </tr>
                <tr
                  :key="'dispatch-vehicle-expanded-' + vehicle"
                  :id="'dispatch-row-' + vehicle"
                  class="collapse pe-0 ps-0"
                >
                  <td colspan="4" class="pe-0 ps-0">
                    <div :id="'dispatch-row-' + vehicle" class="collapse">
                      <div class="table-responsive">
                        <table class="table table-striped mb-3">
                          <thead>
                            <tr>
                              <th scope="col">ID</th>
                              <th scope="col">Location</th>
                              <th scope="col">Pickup</th>
                              <th scope="col">Return</th>
                              <th scope="col">Status</th>
                              <th scope="col">View</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="reservation in _.sortBy(
                                groupedReservations,
                                'return_at'
                              )"
                              :key="
                                'dispatch-reservation-row-' + reservation.id
                              "
                            >
                              <td class="align-middle">
                                {{ reservation.platform_id }}
                              </td>
                              <td class="align-middle">
                                {{ reservation.pickup_location }} →
                                {{ reservation.return_location }}
                              </td>
                              <td class="align-middle">
                                {{
                                  new Date(
                                    reservation.pickup_at
                                  ).toLocaleDateString("en-US", {
                                    timeZone: reservation.pickup_timezone,
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })
                                }}
                              </td>
                              <td class="align-middle">
                                {{
                                  new Date(
                                    reservation.return_at
                                  ).toLocaleDateString("en-US", {
                                    timeZone: reservation.pickup_timezone,
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    hour12: true,
                                  })
                                }}
                              </td>
                              <td class="align-middle">
                                <span class="badge badge-tertiary">{{
                                  reservation.status
                                }}</span>
                              </td>
                              <td class="text-center align-middle">
                                <button
                                  type="button"
                                  class="btn btn-primary btn-sm"
                                  data-bs-toggle="offcanvas"
                                  data-bs-target="
                                  #reservationOffcanvas
                                "
                                  @click="id = reservation.id"
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </td>
                </tr></template
              >
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Reservation View -->
    <div
      class="offcanvas offcanvas-start"
      tabindex="-1"
      id="reservationOffcanvas"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div class="offcanvas-header align-middle">
        <h3 class="highlighted my-0 mt-0 fw-bold">View Reservation</h3>
        <button
          type="button"
          class="btn btn-primary"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          Close
        </button>
      </div>
      <div class="offcanvas-body">
        <ReservationOffcanvas :id="id"></ReservationOffcanvas>
      </div>
    </div>
    <!-- Unassigned Modal -->
    <div class="modal fade" id="unassigned-modal">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="highlighted fw-bold">Assign Vehicles.</h3>
          </div>
          <div class="modal-body pt-0">
            <div class="row g-0">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Pickup</th>
                      <th scope="col">Return</th>
                      <th scope="col">Status</th>
                      <th scope="col">View</th>
                    </tr>
                  </thead>
                  <tbody v-if="reservationsByVehicle.unassigned.length === 0">
                    <tr>
                      <td colspan="5" class="stripe">No actions required.</td>
                    </tr>
                  </tbody>
                  <tbody v-if="reservationsByVehicle.unassigned.length > 0">
                    <tr
                      v-for="reservation in _.sortBy(
                        reservationsByVehicle.unassigned,
                        'pickup_at'
                      )"
                      :key="
                        'dispatch-reservation-row-unassigned-' + reservation.id
                      "
                    >
                      <td class="align-middle" style="white-space: nowrap">
                        {{ reservation.platform_id }}
                      </td>

                      <td class="align-middle">
                        {{
                          new Date(reservation.pickup_at).toLocaleDateString(
                            "en-US",
                            {
                              timeZone: reservation.pickup_timezone,
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )
                        }}
                      </td>
                      <td class="align-middle">
                        {{
                          new Date(reservation.return_at).toLocaleDateString(
                            "en-US",
                            {
                              timeZone: reservation.pickup_timezone,
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )
                        }}
                      </td>
                      <td class="align-middle">
                        <span class="badge badge-tertiary">{{
                          reservation.status
                        }}</span>
                      </td>
                      <td class="text-center align-middle">
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          data-bs-toggle="offcanvas"
                          data-bs-target="
                                  #reservationOffcanvas
                                "
                          @click="id = reservation.id"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="modal-footer mt-auto pe-0">
                <!-- mt-auto ensures the footer stays at the bottom -->
                <button
                  type="button"
                  class="btn btn-primary me-0"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReservationOffcanvas from "@/components/offcanvas/ReservationOffcanvas.vue";

export default {
  name: "DispatchView",
  data: function () {
    return {
      return_after: "",
      return_before: "",
      pickup_after: "",
      pickup_before: "",
      id: "", //For reservation
      pickup_qa: "",
      return_qa: "",
      selected: "today",
      selectedDate: new Date(),
    };
  },
  components: {
    ReservationOffcanvas,
  },
  methods: {
    selectDate: function () {
      let selectedDate;
      const today = new Date();
      switch (this.selected) {
        case "yesterday":
          selectedDate = new Date(today.setDate(today.getDate() - 1));
          break;
        case "tomorrow":
          selectedDate = new Date(today.setDate(today.getDate() + 1));
          break;
        default:
          selectedDate = today;
      }
      // Format selectedDate as needed and perform actions
      console.log(selectedDate); // For demonstration
      // You can emit this date to parent component or use it within this component
      this.selectedDate = selectedDate;
    },
    getVehicleImage(model) {
      return (
        this.$store.state.user.models.find((r) => r.name === model)
          ?.public_image_link || "default-image-link"
      );
    },
    getRegionPrefix(regionId) {
      return (
        this.$store.state.user.regions.find((r) => r.id === regionId)?.prefix ||
        ""
      );
    },
    getVehicleLocation(vin) {
      const location_id = this.$store.state.user.vehicles.find(
        (vehicle) => vehicle.vin === vin
      )
        ? this.$store.state.user.vehicles.find((vehicle) => vehicle.vin === vin)
            .location_id
        : null;

      if (location_id == null) {
        return null;
      }
      return this.$store.state.user.locations.find(
        (location) => location.id === location_id
      )
        ? this.$store.state.user.locations.find(
            (location) => location.id === location_id
          ).name
        : "";
    },
    copyToClipboard(text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          // Optional: Display a message or perform an action to inform the user that the text has been copied
          console.log("Copied to clipboard:", text);
        })
        .catch((err) => {
          console.error("Error in copying text: ", err);
        });
    },
    formatLocation(reservations, currentVehicleLocation) {
      // Function to check if the date is today
      const isToday = (date, timezone) => {
        const today = new Date(this.selectedDate).toLocaleDateString("en-US", {
          timeZone: timezone,
        });
        return (
          new Date(date).toLocaleDateString("en-US", { timeZone: timezone }) ===
          today
        );
      };
      console.log(currentVehicleLocation);
      console.log(reservations);
      const currentVehicle = this.$store.state.user.vehicles.find((v) => {
        return currentVehicleLocation == v.vin;
      });

      const vehicleLocation = this.$store.state.user.locations.find((l) => {
        return l.id == currentVehicle.location_id;
      });

      // Merge returns and pickups into a single array with additional 'type' property
      let events = reservations.flatMap((r) => [
        ...(r.return_at && isToday(r.return_at, r.return_timezone)
          ? [
              {
                ...r,
                type: "Return",
                datetime: r.return_at,
                timezone: r.return_timezone,
                location_id: r.return_location_id,
                location: vehicleLocation,
                photos: r.return_photos,
              },
            ]
          : []),
        ...(r.pickup_at && isToday(r.pickup_at, r.pickup_timezone)
          ? [
              {
                ...r,
                type: "Pickup",
                datetime: r.pickup_at,
                timezone: r.pickup_timezone,
                location_id: r.pickup_location_id,
                location: vehicleLocation,
                photos: r.pickup_photos,
              },
            ]
          : []),
      ]);

      // Sort the events by datetime
      events.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));

      return events;
    },
    getEarliestLegTime(reservations, vin) {
      let legs = this.formatLocation(reservations, vin);
      if (legs.length > 0) {
        legs.sort((a, b) => new Date(a.datetime) - new Date(b.datetime));
        return new Date(legs[0].datetime);
      }
      return new Date(); // return current date if no legs are found
    },
  },
  computed: {
    vehicles() {
      return this.$store.state.user.vehicles;
    },
    reservations() {
      return this.$store.state.user.reservations;
    },
    sortedReservations: function () {
      if (this.$store.state.user.regions.length === 0) {
        return [];
      }
      let filteredReservations = this.reservations;

      filteredReservations = filteredReservations.filter((reservation) => {
        return reservation.status != "Canceled" && reservation.extended != true;
      });

      const getDateTimeInZone = (date, timeZone, startOrEnd) => {
        // Convert the input date to the specified time zone first
        const zoneDate = new Date(
          date.toLocaleString("en-US", { timeZone: timeZone })
        );

        // Adjust the hours, minutes, seconds, and milliseconds based on startOrEnd
        if (startOrEnd === "start") {
          zoneDate.setHours(0, 0, 0, 0); // Set to start of the day in the target time zone
        } else {
          zoneDate.setHours(23, 59, 59, 999); // Set to end of the day in the target time zone
        }

        return zoneDate;
      };

      const today = new Date(this.selectedDate);
      const timeZone = this.$store.state.user.regions.find((region) => {
        return region.id == this.$store.state.user.region;
      }).timezone;

      const todayStart = getDateTimeInZone(today, timeZone, "start");
      const todayEnd = getDateTimeInZone(today, timeZone, "end");

      filteredReservations = filteredReservations.filter((reservation) => {
        const pickupDate = new Date(
          new Date(reservation.pickup_at).toLocaleDateString("en-US", {
            timeZone: reservation.pickup_timezone,
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
        );
        const dropoffDate = new Date(
          new Date(reservation.return_at).toLocaleDateString("en-US", {
            timeZone: reservation.return_timezone,
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })
        );

        return (
          (pickupDate >= todayStart && pickupDate <= todayEnd) ||
          (dropoffDate >= todayStart && dropoffDate <= todayEnd)
        );
      });

      return filteredReservations;
    },
    reservationsByVehicle: function () {
      // eslint-disable-next-line
      const res = this.reservations;

      let groupedReservations = this._.groupBy(
        this.sortedReservations,
        "vehicle_vin"
      );

      // Separate into 'assigned' and 'unassigned'
      let assignedPickups = {};
      let assignedReturns = {};
      let unassigned = [];

      for (let vin in groupedReservations) {
        if (vin === "null" || vin === "undefined") {
          // Add to unassigned array if the vin is null or undefined
          unassigned = unassigned.concat(groupedReservations[vin]);
        } else {
          // Add to assigned object if the vin is defined
          let legs = this.formatLocation(groupedReservations[vin], vin);

          if (
            legs.some((r) => {
              return r.type === "Pickup";
            })
          ) {
            assignedPickups[vin] = groupedReservations[vin];
          } else {
            assignedReturns[vin] = groupedReservations[vin];
          }
        }
      }

      let sortedAssignedPickups = Object.keys(assignedPickups)
        .sort((a, b) => {
          return (
            this.getEarliestLegTime(assignedPickups[a], a) -
            this.getEarliestLegTime(assignedPickups[b], b)
          );
        })
        .reduce((sorted, key) => {
          sorted[key] = assignedPickups[key];
          return sorted;
        }, {});

      let sortedAssignedReturns = Object.keys(assignedReturns)
        .sort((a, b) => {
          return (
            this.getEarliestLegTime(assignedReturns[a], a) -
            this.getEarliestLegTime(assignedReturns[b], b)
          );
        })
        .reduce((sorted, key) => {
          sorted[key] = assignedReturns[key];
          return sorted;
        }, {});

      return {
        pickups: sortedAssignedPickups,
        returns: sortedAssignedReturns,
        unassigned: unassigned,
      };
    },
  },
};
</script>

<style scoped>
.vehicle-card {
  min-width: 250px !important;
}

.reservation-indicator {
  /* Styles for indicating a reservation on a specific day */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

th {
  white-space: nowrap; /* Prevent wrapping in table headers */
}
#reservationOffcanvas {
  min-width: 75% !important;
}
thead th {
  background-color: white; /* To ensure the header is not transparent */
}
.clickable {
  cursor: pointer;
}
.stripe {
  background: rgba(0, 0, 0, 0.05) !important;
}
</style>
