<template>
  <div>
    <div class="fluid-container">
      <div class="row g-0 d-flex" style="min-height: 65vh !important">
        <div class="my-auto">
          <div class="col-10 offset-1">
            <h1 class="display-5 mt-5 mb-3 text-center">
              <span
                class="highlighted"
                v-if="$route.params.code == 'invalid_link'"
                >Ooops, this link has expired.</span
              >
              <span
                class="highlighted"
                v-if="$route.params.code == 'access_denied'"
                >Ooops, you don't have access to that.</span
              >
            </h1>
            <p
              class="mt-3 mb-3 text-center"
              v-if="$route.params.code == 'invalid_link'"
            >
              We appologize, this link has expired. Please proceed to the sign
              in page and request a new link. Please contact support <br />for
              24/7 assistance by chat, phone, or text if this error continues.
            </p>
            <p
              class="mt-3 mb-3 text-center"
              v-if="$route.params.code == 'access_denied'"
            >
              We recieved a request for a resource that you do not have access
              to. Please contact support <br />for 24/7 assistance by chat,
              phone, or text if this error continues.
            </p>
            <div class="text-center">
              <router-link to="/" class="btn btn-primary mt-3 text-center">
                Continue
              </router-link>
              <a
                href="https://www.resla.com/contact"
                class="btn btn-secondary mt-3 text-center"
                target="_blank"
              >
                Contact Us
              </a>
            </div>
          </div>
          <div class="col-12 mb-5"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "ErrorView",
  mounted: function () {
    // Ensure loading is stopped without page refresh
    this.$store.commit("stopLoading");
  },
};
</script>
