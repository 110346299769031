<template>
  <div>
    <div
      class="fluid-container"
      style="min-height: 65vh !important"
      v-if="loading"
    >
      <div class="spinner-container">
        <!-- Spinner -->
        <div class="spinner-border text-primary highlighted" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div class="container" v-if="!loading">
      <!-- Reservations Table -->
      <div class="row g-0">
        <div class="col-12">
          <h1
            class="display-5 mt-5 mb-3 d-flex justify-content-center align-items-center"
          >
            <span class="highlighted me-auto">Sales.</span>

            <button class="btn btn-primary mt-auto me-2" v-on:click="getLeads">
              Refresh
            </button>
          </h1>
          <p v-if="leads.length < 1">
            Looks like there aren't any leads! Contact your supervisor if you
            believe this to be an error.
          </p>
        </div>
      </div>
    </div>
    <div class="container" style="min-height: 65vh !important" v-if="!loading">
      <div class="row g-0">
        <div class="table-responsive">
          <table class="table mb-3 table table-striped">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col" style="width: 25% !important">Progress</th>
                <th scope="col">Created At</th>
                <th scope="col">Email</th>
                <th scope="col">Phone</th>
                <th scope="col">Pickup At</th>
                <th scope="col">Return At</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="lead of leads" :key="'lead-' + lead.id">
                <td class="align-middle">{{ lead.id }}</td>
                <td class="align-middle">
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      aria-label="Basic example"
                      :style="{
                        width:
                          (100 * (lead.last_step / 5)).toFixed(0).toString() +
                          '%',
                      }"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      Step {{ lead.last_step }}
                    </div>
                  </div>
                </td>
                <td class="align-middle">
                  {{
                    new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      timeZone: $store.state.user.region.timezone,
                      hour12: true,
                    }).format(new Date(lead.created_at))
                  }}
                </td>
                <td class="align-middle">{{ lead.email }}</td>
                <td class="align-middle">
                  <a
                    :href="'tel:' + normalizePhoneNumber(lead.phone_number)"
                    v-if="lead.phone_number"
                    >{{
                      lead.phone_number
                        ? normalizePhoneNumber(lead.phone_number)
                        : ""
                    }}</a
                  >
                </td>
                <td class="align-middle">
                  {{
                    new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      timeZone: $store.state.user.region.timezone,
                      hour12: true,
                    }).format(new Date(lead.pick_up_date))
                  }}
                </td>
                <td class="align-middle">
                  {{
                    new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      timeZone: $store.state.user.region.timezone,
                      hour12: true,
                    }).format(new Date(lead.return_date))
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "SalesView",
  data: function () {
    return {
      leads: [],
      loading: false,
    };
  },
  mounted: function () {
    this.getLeads();
  },
  watch: {
    "$store.state.region": {
      handler(newRegion, oldRegion) {
        if (newRegion !== oldRegion && oldRegion !== null) {
          this.getLeads();
        }
      },
    },
  },
  methods: {
    getLeads: function () {
      this.loading = true;
      this.$axios
        .get(
          this.$store.state.root_url +
            "/reservation/attempts?region=" +
            this.$store.state.region,
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
          this.leads = this._.orderBy(response.data, "created_at", ["desc"]);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    normalizePhoneNumber: function (phoneNumber) {
      // Step 1: Remove all non-digit characters, except the leading plus sign.
      let cleanNumber = phoneNumber.replace(/(?!^\+)[^\d]/g, "");

      // Step 2 & 3: Check for country code and prepend if absent.
      // This example assumes numbers without a leading '+' are US numbers and adds '+1'.
      // Adjust the 'if' condition and country code as necessary for different assumptions or number formats.
      if (!cleanNumber.startsWith("+")) {
        // Assuming US numbers without a country code
        cleanNumber = "+1" + cleanNumber;
      }

      return cleanNumber;
    },
  },
};
</script>
<style>
.spinner-container {
  position: absolute; /* or 'fixed' if you want it relative to the viewport */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  background-color: white !important;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
}
</style>
