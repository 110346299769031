var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row g-0",staticStyle:{"min-height":"65vh !important"}},[_c('div',{staticClass:"col-12"},[_c('h1',{staticClass:"display-5 mt-5 mb-3 d-flex justify-content-center align-items-center"},[_c('span',{staticClass:"highlighted me-auto"},[_vm._v("Schedule.")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"form-select w-25 mt-auto me-2",attrs:{"id":"dateSelect"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.selectDate]}},[_c('option',{attrs:{"value":"yesterday"}},[_vm._v("Yesterday")]),_c('option',{attrs:{"value":"today","selected":""}},[_vm._v("Today")]),_c('option',{attrs:{"value":"tomorrow"}},[_vm._v("Tomorrow")])])]),(!_vm.$store.state.user.reservations.length)?_c('p',[_vm._v(" Looks like you don't have access to any reservations! Contact your supervisor to get access. ")]):_vm._e(),(_vm.unassignedReservations.length > 0)?_c('h3',{staticClass:"mt-3"},[_vm._v(" Unassigned ")]):_vm._e(),(_vm.unassignedReservations.length > 0)?_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped mb-3"},[_vm._m(0),_c('tbody',{staticClass:"mt-3"},_vm._l((_vm.unassignedReservations),function(reservation){return _c('tr',{key:'reservation-cleaning-unassigned-' +
                  reservation.platform_id +
                  '-' +
                  reservation.type},[_c('td',{staticClass:"align-middle"},[_vm._v(_vm._s(reservation.platform_id))]),_c('td',{staticClass:"align-middle"},[(!reservation.vehicle_vin)?_c('span',{staticClass:"badge badge-primary"},[_vm._v("!")]):_vm._e()]),_c('td',{staticClass:"align-middle text-end"},[_c('button',{staticClass:"btn btn-primary btn-sm",attrs:{"type":"button","data-bs-toggle":"offcanvas","data-bs-target":"\n                                #reservationOffcanvas-schedule\n                              "},on:{"click":function($event){_vm.id = reservation.id}}},[_vm._v(" View ")])])])}),0)])]):_vm._e(),_c('h3',{staticClass:"mt-3"},[_vm._v("Assigned")]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table mb-3 table-striped"},[_vm._m(1),_c('tbody',[(
                  _vm.sortedPickups.length === 0 && _vm.sortedReturns.length === 0
                )?_c('tr',[_c('td',{staticStyle:{"background-color":"#f7f7f7"},attrs:{"colspan":"8"}},[_vm._v(" No results ")])]):_vm._e()]),_c('tbody',{staticClass:"mt-3"},_vm._l((_vm.filteredReservations),function(reservation){return _c('tr',{key:'reservation-cleaning-' +
                  reservation.platform_id +
                  '-' +
                  reservation.type},[_c('td',{staticClass:"align-middle",staticStyle:{"min-width":"150px !important"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(reservation.order),expression:"reservation.order"}],staticClass:"form-control",attrs:{"type":"number","placeholder":""},domProps:{"value":(reservation.order)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(reservation, "order", $event.target.value)},function($event){return _vm.debounceUpdateOrder(reservation)}]}})]),_c('td',{staticClass:"align-middle"},[_c('span',{staticClass:"badge badge-tertiary"},[_vm._v(_vm._s(reservation.type[0].toUpperCase()))])]),_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(reservation.vehicle_vin)+" ")]),_c('td',{staticClass:"align-middle"},[_vm._v(_vm._s(reservation.platform_id))]),_c('td',{staticClass:"align-middle"},[(reservation.action === true)?_c('span',{staticClass:"badge badge-tertiary"},[_vm._v("Complete")]):_vm._e(),(reservation.action === false)?_c('span',{staticClass:"badge badge-primary"},[_vm._v("Incomplete")]):_vm._e()]),_c('td',{staticClass:"align-middle",staticStyle:{"white-space":"nowrap !important"}},[(reservation.status == 'Pending')?_c('span',[_vm._v("Pending")]):_vm._e(),(
                      reservation.status == 'On Rent' &&
                      reservation.late == false
                    )?_c('span',[_vm._v("On Rent")]):_vm._e(),(
                      reservation.status == 'On Rent' &&
                      reservation.late == true
                    )?_c('span',[_vm._v("On Rent (Late)")]):_vm._e(),(reservation.status == 'Canceled')?_c('span',[_vm._v("Canceled")]):_vm._e(),(
                      reservation.status == 'Returned' &&
                      reservation.late == false
                    )?_c('span',[_vm._v("Returned")]):_vm._e(),(
                      reservation.status == 'Returned' &&
                      reservation.late == true
                    )?_c('span',[_vm._v("Returned (Late)")]):_vm._e()]),_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(_vm.$store.state.user.locations.find(function (location) { return reservation.location_id == location.hq_id; }) ? _vm.$store.state.user.locations.find(function (location) { return reservation.location_id == location.hq_id; }).name : "")+" ")]),_c('td',{staticClass:"align-middle"},[_vm._v(" "+_vm._s(new Date(reservation.date).toLocaleDateString("en-US", { timeZone: reservation.timezone, hour: "2-digit", minute: "2-digit", hour12: true, }))+" ")]),_c('td',{staticClass:"align-middle",staticStyle:{"min-width":"150px !important"}},[_c('select',{staticClass:"form-control form-select d-inline ms-2",domProps:{"value":reservation.user_id},on:{"change":function($event){return _vm.onAssignmentChange($event, reservation)}}},[_c('option',{attrs:{"value":""}}),_vm._l((_vm.$store.state.user.employees),function(employee){return _c('option',{key:'dispatch-employee-select-' + employee.user_id,domProps:{"value":employee.user_id}},[_vm._v(" "+_vm._s(employee.name.first_name)+" "+_vm._s(employee.name.last_name)+" ")])})],2)]),_c('td',{staticClass:"align-middle"},[_c('button',{staticClass:"btn btn-primary btn-sm",attrs:{"type":"button","data-bs-toggle":"offcanvas","data-bs-target":"\n                                #reservationOffcanvas-schedule\n                              "},on:{"click":function($event){_vm.id = reservation.id}}},[_vm._v(" View ")])])])}),0)])])]),_c('div',{staticClass:"col-12 mb-5"})])]),_c('div',{staticClass:"offcanvas offcanvas-start",attrs:{"tabindex":"-1","id":"reservationOffcanvas-schedule","aria-labelledby":"offcanvasExampleLabel"}},[_vm._m(2),_c('div',{staticClass:"offcanvas-body"},[_c('ReservationOffcanvas',{attrs:{"id":_vm.id}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"border-none"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("ID")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Vehicle")]),_c('th',{staticClass:"text-end",attrs:{"scope":"col"}},[_vm._v("Action")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"border-none"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Order")]),_c('th',{attrs:{"scolpe":"col"}},[_vm._v("Type")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("ID")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("VIN")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Cleaning")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Status")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Location")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Time")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Assigned")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Action")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"offcanvas-header align-middle"},[_c('h3',{staticClass:"highlighted my-0 mt-0 fw-bold"},[_vm._v("View Reservation")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-bs-dismiss":"offcanvas","aria-label":"Close"}},[_vm._v(" Close ")])])}]

export { render, staticRenderFns }