<template>
  <div>
    <b-container>
      <b-row no-gutters style="min-height: 65vh !important" class="d-flex">
        <b-col class="text-center my-auto">
          <b-spinner
            label="Spinning"
            type="grow"
            class="text-center mt-5 bg-primary"
          ></b-spinner>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "AuthenticateView",
  mounted: function () {
    // Get Params from URL
    const params = new URLSearchParams(window.location.search);

    // Get Token From Params
    const token = params.get("token");

    if (token != null) {
      // Set Authentication Locally
      this.$store.commit("setAuthentication", token);

      // Route to Home
      this.$router.push("/");
    } else {
      this.$router.push("/get-started");
    }
  },
};
</script>
