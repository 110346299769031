<template>
  <div style="min-height: 65vh !important">
    <div class="container">
      <div class="row g-0">
        <div class="col-12">
          <h1
            class="display-5 mt-5 mb-3 d-flex justify-content-center align-items-center"
          >
            <span class="highlighted me-auto">Billing.</span>
            <button
              class="btn btn-primary mt-auto me-2"
              data-bs-toggle="offcanvas"
              data-bs-target="#paymentsOffcanvas"
            >
              Filters
            </button>
          </h1>
        </div>
      </div>
    </div>
    <div class="container">
      <!-- Reservations Table -->
      <div class="row g-0">
        <div class="col-12 mb-5" v-if="reservations.length">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Platform ID</th>
                  <th>Reservation Status</th>
                  <th>Billing Status</th>
                  <th>Charging Method</th>
                  <th>Payment Balance</th>
                  <th>Charging Balance</th>
                  <th>Security Balance</th>
                  <th>Return Time</th>
                  <th>Reservation Notes</th>
                  <th>Action</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="reservation in sortedReservations"
                  :key="'payments-' + reservation.id"
                >
                  <td class="align-middle">
                    {{ reservation.platform_id }}
                  </td>
                  <td class="align-middle">
                    <select
                      class="form-control form-select w-75"
                      style="min-width: 250px"
                      :value="reservation.status"
                      disabled
                    >
                      <option value="Pending">Pending</option>
                      <option value="On Rent">On Rent</option>
                      <option value="Returned">Returned</option>
                      <option value="Canceled">Canceled</option>
                    </select>
                  </td>
                  <td class="align-middle">
                    <span
                      class="badge badge-primary"
                      v-if="reservation.billing_complete == false"
                      >Open</span
                    >
                    <span
                      class="badge badge-tertiary"
                      v-if="reservation.billing_complete == true"
                      >Closed</span
                    >
                  </td>
                  <td class="align-middle">
                    {{ reservation.charging_method }}
                  </td>
                  <td class="align-middle">
                    <span>
                      {{
                        new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(reservation.payment_balance)
                      }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span
                      v-if="reservation.charging_method == 'Standard Fleet'"
                    >
                      {{
                        new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(reservation.charging_balance / 100)
                      }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span>
                      {{
                        new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(reservation.security_balance)
                      }}
                    </span>
                  </td>
                  <td class="align-middle">
                    <span
                      v-if="
                        reservation.status == 'Returned' &&
                        reservation.returned_at
                      "
                    >
                      {{
                        formatReturnTimeDifference(reservation, "short_label")
                      }}
                    </span>
                  </td>
                  <td class="align-middle" style="min-width: 250px">
                    <textarea
                      class="form-control"
                      @input="onNotesChange($event, reservation)"
                      :value="reservation.notes"
                    ></textarea>
                  </td>
                  <td class="align-middle text-center">
                    <div
                      class="btn-group btn-group-sm align-middle"
                      role="group"
                      aria-label="Button group with nested dropdown"
                    >
                      <div class="btn-group" role="group">
                        <button
                          type="button"
                          class="btn btn-primary btn-sm dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Action
                        </button>
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              class="dropdown-item"
                              @click="
                                updateBillingStatus(reservation, 'Complete')
                              "
                              >Mark as Closed</a
                            >
                          </li>
                          <li>
                            <a
                              class="dropdown-item"
                              @click="updateBillingStatus(reservation, 'Open')"
                              >Mark as Open</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                  <td class="align-middle">
                    <div
                      class="btn-group btn-group-sm align-middle"
                      role="group"
                      aria-label="Button group with nested dropdown"
                    >
                      <div class="btn-group" role="group">
                        <button
                          type="button"
                          class="btn btn-primary btn-sm dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          View
                        </button>
                        <ul class="dropdown-menu">
                          <li>
                            <a
                              class="dropdown-item"
                              data-bs-toggle="offcanvas"
                              data-bs-target="
                                #reservationOffcanvas
                              "
                              @click="id = reservation.id"
                              >Reservation</a
                            >
                          </li>
                          <li v-if="reservation.vehicle_vin">
                            <a
                              class="dropdown-item"
                              target="_blank"
                              :href="
                                'https://fleetweb.standardfleet.com/supercharging?vins=' +
                                reservation.vehicle_vin +
                                '&startDate=' +
                                new Date(reservation.pickup_at).toISOString() +
                                '&endDate=' +
                                new Date(reservation.return_at).toISOString()
                              "
                              >Supercharging Sessions</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- Reservation View -->
    <div
      class="offcanvas offcanvas-start"
      tabindex="-1"
      id="reservationOffcanvas"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div class="offcanvas-header align-middle">
        <h3 class="highlighted my-0 mt-0 fw-bold">View Reservation</h3>
        <button
          type="button"
          class="btn btn-primary"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          Close
        </button>
      </div>
      <div class="offcanvas-body">
        <ReservationOffcanvas :id="id"></ReservationOffcanvas>
      </div>
    </div>
    <!-- Filters -->
    <div
      class="offcanvas offcanvas-start"
      tabindex="-1"
      id="paymentsOffcanvas"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div class="offcanvas-header align-middle">
        <h3 class="highlighted my-0 mt-0 fw-bold">Filters</h3>
        <button
          type="button"
          class="btn btn-primary"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          Close
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="mt-3 mb-3"></div>
        <h6>Sort By</h6>
        <label class="mt-3">Attribute</label>
        <!-- Sort By -->
        <select class="form-control form-select w-100" v-model="sorted">
          <option value="id">ID</option>
          <option value="status">Status</option>
          <option value="payment_balance">Owing</option>
          <option value="pickup_at">Pickup At</option>
          <option value="return_at">Return At</option>
        </select>
        <label class="mt-3">Order</label>
        <!-- Sort By -->
        <select class="form-control form-select w-100 mb-3" v-model="order">
          <option value="desc">Descending</option>
          <option value="asc">Ascending</option>
        </select>

        <h6 class="mt-5">Filter By</h6>
        <!-- Filter by License Status -->
        <label class="mt-3">Filter by Billing Status</label>
        <select class="form-control form-select w-100" v-model="billing_status">
          <option value="">All</option>
          <option value="false">Open</option>
          <option value="true">Closed</option>
        </select>
        <!-- Filter by Status -->
        <label class="mt-3">Filter by Reservation Status</label>
        <select class="form-control form-select w-100" v-model="status">
          <option value="">All</option>
          <option value="Pending">Pending</option>
          <option value="On Rent">On Rent</option>
          <option value="Returned">Returned</option>
          <option value="Canceled">Canceled</option>
        </select>
        <!-- Filter by Payment Method -->
        <label class="mt-3">Filter by Charging Method</label>
        <select
          class="form-control form-select w-100"
          v-model="charging_method"
        >
          <option value="">All</option>
          <option value="Manual">Manual</option>
          <option value="Standard Fleet">Standard Fleet</option>
          <option value="Payment Responsible">Payment Responsible</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import ReservationOffcanvas from "@/components/offcanvas/ReservationOffcanvas.vue";

export default {
  name: "BillingView",
  data: function () {
    return {
      id: "", //For reservation
      status: "Returned",
      sorted: "return_at",
      order: "asc",
      billing_status: "false",
      charging_method: "",
    };
  },
  components: {
    ReservationOffcanvas,
  },
  computed: {
    reservations() {
      return this.$store.state.user.reservations.filter((reservation) => {
        return reservation.status != "Canceled";
      });
    },
    sortedReservations: function () {
      // Step 1a: Filter based on status filter
      let filteredReservations = this.reservations;

      //Step 1b: Filter Status
      if (this.status !== "") {
        filteredReservations = filteredReservations.filter((reservation) => {
          return reservation.status === this.status;
        });
      }
      //Step 1b: Filter License Status
      if (this.billing_status !== "") {
        const licenseBool = this.billing_status === "true";
        filteredReservations = filteredReservations.filter((reservation) => {
          return reservation.billing_complete === licenseBool;
        });
      }

      //Step 1c: Filter Charging Method Type
      if (this.charging_method !== "") {
        filteredReservations = filteredReservations.filter((reservation) => {
          return reservation.charging_method === this.charging_method;
        });
      }

      // Step 2: Determine sort iteratee based on the field type
      let iteratees;
      switch (this.sorted) {
        case "pickup_at":
        case "return_at":
          // For datetime fields, parse them as dates
          iteratees = [(r) => new Date(r[this.sorted])];
          break;
        default:
          // For other fields, use the value as is
          iteratees = [this.sorted];
          break;
      }

      return this.lodash.orderBy(filteredReservations, iteratees, [this.order]);
    },
  },
  created: function () {
    this.debouncedUpdateNotes = this.lodash.debounce(this.updateNotes, 4000);
  },
  methods: {
    updateNotes: function (reservation, notes) {
      this.$axios
        .post(
          this.$store.state.root_url + "/reservation/notes",
          {
            id: reservation.id,
            notes: notes,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onNotesChange: function (event, reservation) {
      const newNotes = event.target.value;
      this.debouncedUpdateNotes(reservation, newNotes);
    },
    updateBillingStatus: function (reservation, status) {
      this.$axios
        .post(
          this.$store.state.root_url + "/reservation/billing",
          {
            id: reservation.id,
            status: status,
          },
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    formatReturnTimeDifference: function (reservation, label) {
      const returnedAt = new Date(reservation.returned_at);
      const tripEnd = new Date(reservation.return_at);
      const timeZoneOptions = {
        timeZone: reservation.return_timezone,
        hour12: false,
      };

      // Adjust dates to the reservation's timezone
      returnedAt.toLocaleString("en-US", timeZoneOptions);
      tripEnd.toLocaleString("en-US", timeZoneOptions);

      // Calculate the time difference in minutes
      const timeDiffMinutes = (tripEnd - returnedAt) / 60000; // Convert milliseconds to minutes

      // Determine the label type
      const isShortLabel = label === "short_label";
      console.log(timeDiffMinutes);
      console.log(Math.floor(timeDiffMinutes));

      if (
        timeDiffMinutes >= 0 ||
        (timeDiffMinutes < 1 && timeDiffMinutes > -1)
      ) {
        // Trip ended early
        if (timeDiffMinutes < 1 && timeDiffMinutes > -1) {
          return "on time";
        }
        if (timeDiffMinutes < 60) {
          return isShortLabel
            ? `${timeDiffMinutes.toFixed(2)} minutes early`
            : `${Math.floor(timeDiffMinutes)} min early`;
        }
        const hours = Math.floor(timeDiffMinutes / 60);
        if (hours >= 48) {
          const days = Math.floor(hours / 24);
          return isShortLabel ? `${days} days early` : `${days} days early`;
        }
        return isShortLabel ? `${hours} hours early` : `${hours} hrs early`;
      } else {
        // Trip has ended and was returned late
        const lateMinutes = Math.abs(timeDiffMinutes);
        if (lateMinutes < 60) {
          return isShortLabel
            ? `${Math.floor(lateMinutes.toFixed(2))} minutes late`
            : `${Math.floor(lateMinutes)} mins late`;
        }
        const hoursLate = Math.floor(lateMinutes / 60);
        if (hoursLate >= 48) {
          const daysLate = Math.floor(hoursLate / 24);
          return isShortLabel
            ? `${daysLate} days late`
            : `${daysLate} days late`;
        }
        return isShortLabel
          ? `${hoursLate} hours late`
          : `${hoursLate} hrs late`;
      }
    },
  },
};
</script>
<style scoped>
#reservationOffcanvas {
  min-width: 75% !important;
}
</style>
