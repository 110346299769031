var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.setup && _vm.$store.state.authenticated)?_c('div',{staticClass:"loading"},[_c('div',{staticClass:"spinner-container"},[_vm._m(0),_c('div',{staticClass:"mt-3 highlighted h3 fw-bold"},[(
          !_vm.$store.state.loading &&
          _vm.$store.state.isConnectionEstablished == false
        )?_c('span',[_vm._v(" Establishing connection ")]):_vm._e(),(
          _vm.$store.state.isConnectionEstablished == true &&
          _vm.$store.state.isRegionGathered == false
        )?_c('span',[_vm._v("Gathering regions")]):_vm._e(),(
          _vm.$store.state.isConnectionEstablished == true &&
          _vm.$store.state.isRegionGathered == true
        )?_c('span',[_vm._v(" Loading data ")]):_vm._e(),_c('span',{staticClass:"dot"},[_vm._v(".")]),_c('span',{staticClass:"dot"},[_vm._v(".")]),_c('span',{staticClass:"dot"},[_vm._v(".")])]),_c('div',{staticClass:"mt-1"},[_vm._v("Hang tight, we are setting up your system.")])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spinner-border text-primary highlighted",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])])}]

export { render, staticRenderFns }