<template>
    <div class="reservations">
      <div class="fluid-container">
        <div class="row g-0" style="min-height:65vh !important;">
          <div class="col-10 offset-1 my-auto text-center">
            <h1 class="display-5 mt-5 mb-3">
              <span class="highlighted">Page not found.</span>
            </h1>
            <p>Ooops! We couldn't find what you're looking for. If this issue continues, please contact support <br>for 24/7 assistance by chat, phone, or text.</p>
            <a href="/" class="btn btn-primary">Back to home</a>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  //import HelloWorld from "@/components/HelloWorld.vue";
  
  export default {
    name: "PageNotFoundView",
    mounted: function(){
        this.$store.commit("stopLoading")
    }
  };
  </script>