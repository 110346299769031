<template>
  <div class="fluid-container black">
    <footer class="py-5 pt-3">
      <div class="row g-0 d-flex">
        <div class="col-4 offset-1 py-2 pb-2 d-none d-md-block">
          <img
            src="../assets/resla-logo-white-rgb-600px@300ppi.png"
            height="15vh"
          />
        </div>
        <div class="col-10 offset-1 py-2 pb-2 d-md-none text-center">
          <img
            src="../assets/resla-logo-white-rgb-600px@300ppi.png"
            height="15vh"
            class="mb-3"
          />
        </div>
        <div class="col-auto ms-auto my-auto d-none d-md-block">
          <a href="mailto:support@resla.com" class="text-white"
            >support@resla.com</a
          >
          | <a href="tel:+14804394079" class="text-white">(480) 439-4079</a>
        </div>
        <div class="col-1"></div>
        <div class="col-10 offset-1 border-bottom grey-border"></div>
        <div
          class="col-10 offset-1 border-bottom grey-border py-2 pb-2 text-center white"
        >
          You’re always guaranteed a Tesla
        </div>

        <div class="col-10 offset-1 py-2 pb-2 text-center small">
          Resla © 2022 | All rights reserved.
        </div>
        <div class="col-10 offset-1 py-2 pb-2 text-center d-md-none">
          <a href="mailto:support@resla.com" class="text-white"
            >support@resla.com</a
          >
          | <a href="tel:+14804394079" class="text-white">(480) 439-4079</a>
        </div>

        <div class="col-12">
          <ul class="nav text-center mt-3 justify-content-center small">
            <li class="nav-item">
              <a
                href="https://www.resla.com/"
                target="_blank"
                class="nav-link white"
                >Home</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://www.resla.com/contact"
                target="_blank"
                class="nav-link white"
                >Contact Us</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://www.resla.com/disclaimers"
                target="_blank"
                class="nav-link white"
                >Disclaimers</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://www.resla.com/privacy"
                target="_blank"
                class="nav-link white"
                >Privacy</a
              >
            </li>
            <li class="nav-item">
              <a
                href="https://www.resla.com/terms"
                target="_blank"
                class="nav-link white"
                >Terms</a
              >
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "FooterSection",
};
</script>
