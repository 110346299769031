<template>
  <div>
    <div class="fluid-container">
      <div class="row g-0 d-flex" style="min-height: 65vh !important">
        <div class="my-auto">
          <div class="col-10 offset-1">
            <h1 class="display-5 mt-5 mb-3 text-center">
              <span class="highlighted">Get started.</span>
            </h1>
          </div>
          <div
            class="col-10 offset-1 col-md-6 offset-md-3 col-lg-4 offset-lg-4"
          >
            <vue-tel-input
              v-model="phone"
              defaultCountry="us"
              @validate="onPhoneValidation"
              v-if="inputType === 'Phone'"
            ></vue-tel-input>
            <!-- Email Input -->
            <input
              v-else
              type="email"
              v-model="email"
              class="form-control"
              placeholder="Enter your email"
              @input="onEmailValidation"
            />
            <button
              v-on:click="sendMessage"
              class="btn btn-primary mt-3 w-100"
              :disabled="!params.valid || loading"
            >
              <span v-if="!loading">Next</span>
              <span v-else
                ><div
                  class="spinner-border"
                  style="height: 1rem !important; width: 1rem !important"
                  role="status"
                >
                  <span class="visually-hidden">Loading...</span>
                </div></span
              >
            </button>

            <p
              class="text-center mt-3 text-muted"
              style="
                font-size: 0.75rem !important;
                line-height: initial !important;
              "
            >
              Want to login with your
              <span v-if="inputType === 'Phone'">email</span>
              <span v-if="inputType === 'Email'">phone</span> instead? Click
              <a
                @click="
                  inputType = 'Phone';
                  params.valid = false;
                  phone = '';
                "
                v-if="inputType === 'Email'"
                class="mb-3"
                >here.</a
              >
              <a
                @click="
                  inputType = 'Email';
                  params.valid = false;
                  email = '';
                "
                v-if="inputType === 'Phone'"
                class="pb-3"
                >here.</a
              >
            </p>
            <p
              class="text-center mt-3 text-muted"
              style="
                font-size: 0.75rem !important;
                line-height: initial !important;
              "
            >
              Please enter your phone number to get started. By providing your
              phone number, you consent to receive text messages from Resla.com.
              Standard messaging and data rates may apply. Refer to our
              <a href="https://www.resla.com/terms" target="_blank">Terms</a>
              and
              <a href="https://www.resla.com/privacy" target="_blank"
                >Privacy Policy</a
              >
              for more details.
            </p>
          </div>
          <div class="col-12 mb-5"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginView",
  data: function () {
    return {
      loading: false,
      phone: "",
      email: "",
      params: {
        valid: false,
      },
      inputType: "Email",
    };
  },
  methods: {
    sendMessage: function () {
      this.loading = true;
      // Modify the API endpoint or parameters based on input type
      let path =
        this.inputType === "Phone"
          ? "/init/auth?type=phone&phone=" + this.phone
          : "/init/auth?type=email&email=" + this.email;
      this.$axios
        .get(this.$store.state.root_url + path, { params: { auth: false } })
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.phone = "";
          this.email = "";
          this.$router.push(
            "/get-started/confirmation?type=" +
              encodeURIComponent(
                (this.inputType == "Email"
                  ? "email"
                  : "text messages"
                ).toString()
              )
          );
        });
    },
    onPhoneValidation: function (params) {
      console.log(params);
      this.params = params;
    },
    onEmailValidation: function () {
      // eslint-disable-next-line no-useless-escape
      this.params.valid = this.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
        ? true
        : false;
    },
  },
};
</script>
