<template>
  <div>
    <div class="container">
      <div
        class="row g-0"
        style="min-height: 65vh !important; max-height: 65vh !important"
      >
        <div class="col-12">
          <h1
            class="display-5 mt-5 mb-3 d-flex justify-content-center align-items-center"
          >
            <span class="highlighted me-auto">My Dashboard.</span>
          </h1>
          <router-link to="/vehicles" tag="button" class="btn btn-primary me-1"
            >Vehicles</router-link
          >
          <router-link
            to="/reservations"
            tag="button"
            class="btn btn-primary me-1"
            >Reservations</router-link
          >
          <router-link to="/dispatch" tag="button" class="btn btn-primary me-1"
            >Dispatch</router-link
          >
          <router-link to="/schedule" tag="button" class="btn btn-primary me-1"
            >Schedule</router-link
          >
          <router-link to="/billing" tag="button" class="btn btn-primary me-1"
            >Billing</router-link
          >
          <router-link to="/sales" tag="button" class="btn btn-primary"
            >Sales</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
};
</script>

<style scoped>
.sticky-column {
  position: sticky; /* Make it sticky on larger screens */
  position: -webkit-sticky; /* Compatibility for older webkit browsers */
  top: 0; /* Necessary for sticky positioning */
  left: 0;
  background-color: white;
  z-index: 2;
}

/* Media query for screens smaller than 768px (Bootstrap md breakpoint) */
@media (max-width: 768px) {
  .sticky-column {
    position: static; /* Non-sticky on small screens */
  }
}
.vehicle-card {
  min-width: 250px !important;
}

.table-responsive {
  overflow-x: auto;
}

.reservation-indicator {
  /* Styles for indicating a reservation on a specific day */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

th {
  white-space: nowrap; /* Prevent wrapping in table headers */
}
@media (min-width: 992px) {
  /* Adjust for larger screens */
  .offcanvas.offcanvas-start {
    min-width: 35%; /* Half the width of the page */
  }
}
</style>
