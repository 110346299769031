<template>
  <div style="min-height: 65vh !important">
    <div class="container">
      <!-- Vehicles -->
      <div class="row g-0">
        <div class="col-12">
          <h1
            class="display-5 mt-5 mb-3 d-flex justify-content-center align-items-center"
          >
            <span class="highlighted me-auto">Search.</span>

            <input
              type="text"
              class="form-control w-auto mt-auto d-none d-md-block"
              placeholder="Search by Reservation."
              v-model="query"
              @input="debouncedGetReservations"
            />
          </h1>
          <label class="d-md-none">Search:</label>
          <input
            type="text"
            class="form-control w-100 d-md-none mb-3"
            placeholder="Search by Reservation."
            v-model="query"
            @input="debouncedGetReservations"
          />
        </div>
      </div>
    </div>

    <div class="fluid-container">
      <!-- Reservations Table -->
      <div class="row g-0" v-if="reservations.length != 0">
        <div
          class="table-responsive"
          style="min-height: 65vh !important; max-height: 65vh !important"
        >
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Platform Id</th>
                <th>Vehicle</th>
                <th>Pickup Time</th>
                <th>Pickup Location</th>
                <th>Return Time</th>
                <th>Return Location</th>
                <th>Renter</th>
                <th>Phone</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="reservation in reservations"
                :key="'reservation-' + reservation.platform_id"
              >
                <td class="border align-middle text-center">
                  {{ reservation.platform_id }}
                </td>
                <td class="border align-middle text-nowrap">
                  <div
                    class="vehicle-card d-flex align-items-center"
                    v-if="reservation.vehicle_vin"
                  >
                    <img
                      :src="getVehicleImage(reservation.vehicle_model)"
                      class="rounded me-2"
                      alt="Vehicle Image"
                      style="height: 5vh"
                    />
                    <div class="d-flex flex-grow-1 align-items-center">
                      <span class="align-items-center flex-grow-1">
                        <h5
                          class="mb-0 clickable"
                          @click="copyToClipboard(reservation.vehicle_vin)"
                        >
                          {{
                            $store.state.user.vehicles.find(
                              (vehicle) =>
                                vehicle.vin === reservation.vehicle_vin
                            )
                              ? $store.state.user.vehicles.find(
                                  (vehicle) =>
                                    vehicle.vin === reservation.vehicle_vin
                                ).model
                              : ""
                          }}
                          <span v-if="reservation.vehicle_vin"
                            >({{ reservation.vehicle_vin.slice(-6) }})</span
                          >
                        </h5>
                        <p class="mb-0 mt-0" v-if="reservation.vehicle_vin">
                          {{ getVehicleLocation(reservation.vehicle_vin) }}
                        </p>
                        <p class="mb-0">
                          <span
                            class="badge badge-secondary me-1 clickable"
                            @click="
                              copyToClipboard(
                                $store.state.user.vehicles.find(
                                  (vehicle) =>
                                    vehicle.vin === reservation.vehicle_vin
                                )
                                  ? $store.state.user.vehicles.find(
                                      (vehicle) =>
                                        vehicle.vin === reservation.vehicle_vin
                                    ).license_plate
                                  : ''
                              )
                            "
                          >
                            {{
                              $store.state.user.vehicles.find(
                                (vehicle) =>
                                  vehicle.vin === reservation.vehicle_vin
                              )
                                ? $store.state.user.vehicles.find(
                                    (vehicle) =>
                                      vehicle.vin === reservation.vehicle_vin
                                  ).license_plate
                                : ""
                            }}
                          </span>
                          <span class="badge">
                            {{
                              $store.state.user.vehicles.find(
                                (vehicle) =>
                                  vehicle.vin === reservation.vehicle_vin
                              )
                                ? getRegionPrefix(
                                    $store.state.user.vehicles.find(
                                      (vehicle) =>
                                        vehicle.vin === reservation.vehicle_vin
                                    ).region_id
                                  )
                                : ""
                            }}
                          </span>
                          <span class="ms-1 badge badge-tertiary">{{
                            $store.state.user.vehicles.find(
                              (vehicle) =>
                                vehicle.vin === reservation.vehicle_vin
                            )
                              ? $store.state.user.vehicles.find(
                                  (vehicle) =>
                                    vehicle.vin === reservation.vehicle_vin
                                ).status
                              : ""
                          }}</span>
                        </p>
                      </span>
                    </div>
                  </div>
                </td>
                <td class="text-center border align-middle">
                  {{
                    new Date(reservation.pickup_at).toLocaleDateString(
                      "en-US",
                      {
                        timeZone: reservation.pickup_timezone,
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      }
                    )
                  }}
                </td>
                <td class="text-center border align-middle">
                  {{
                    $store.state.user.locations.find(
                      (l) => l.hq_id === reservation.pickup_location_id
                    )
                      ? $store.state.user.locations.find(
                          (l) => l.hq_id === reservation.pickup_location_id
                        ).name
                      : ""
                  }}
                </td>
                <td class="text-center border align-middle">
                  {{
                    new Date(reservation.return_at).toLocaleDateString(
                      "en-US",
                      {
                        timeZone: reservation.return_timezone,
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                      }
                    )
                  }}
                </td>
                <td class="text-center border align-middle">
                  {{
                    $store.state.user.locations.find(
                      (l) => l.hq_id === reservation.return_location_id
                    )
                      ? $store.state.user.locations.find(
                          (l) => l.hq_id === reservation.return_location_id
                        ).name
                      : ""
                  }}
                </td>
                <td class="border align-middle">
                  {{ reservation.customer_first_name }}
                  {{ reservation.customer_last_name }}
                </td>
                <td class="border align-middle">{{ reservation.phone }}</td>
                <td class="border align-middle" style="min-width: 250px">
                  <select
                    class="form-control form-select w-100"
                    :value="reservation.status"
                    :disabled="true"
                  >
                    <option value="Pending">Pending</option>
                    <option value="On Rent">On Rent</option>
                    <option value="Returned">Returned</option>
                    <option value="Canceled">Canceled</option>
                  </select>
                </td>
                <td class="text-center border align-middle">
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    data-bs-toggle="offcanvas"
                    data-bs-target="
                                #reservationOffcanvas
                              "
                    @click="clickModal(reservation)"
                  >
                    View
                  </button>
                </td>
              </tr>
              <tr>
                <td
                  :colspan="12"
                  v-if="reservations.length == 0 || !reservations"
                >
                  No results found.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Reservation View -->
    <div
      class="offcanvas offcanvas-start"
      tabindex="-1"
      id="reservationOffcanvas"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div class="offcanvas-header align-middle">
        <h3 class="highlighted my-0 mt-0 fw-bold">View Reservation</h3>
        <button
          type="button"
          class="btn btn-primary"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          Close
        </button>
      </div>
      <div class="offcanvas-body">
        <ReservationOffcanvas :id="id"></ReservationOffcanvas>
      </div>
    </div>
  </div>
</template>

<script>
import ReservationOffcanvas from "@/components/offcanvas/ReservationOffcanvas.vue";

export default {
  name: "SearchView",
  data: function () {
    return {
      id: "",
      query: "",
      reservations: [],
    };
  },
  components: {
    ReservationOffcanvas,
  },
  created: function () {
    // Instantiate the debounced function
    this.debouncedGetReservations = this._.debounce(this.getReservations, 300);
  },
  mounted: function () {
    this.getReservations();
  },
  methods: {
    clickModal: function (reservation) {
      this.id = reservation.id;
      const exists = this.$store.state.user.reservations.some(
        (r) => r.platform_id === reservation.platform_id
      );
      if (!exists) {
        this.$store.commit("insertReservation", reservation);
      }
    },
    getReservations: function () {
      this.$axios
        .get(
          this.$store.state.root_url +
            "/search?query=" +
            this.query.replace(/\s+/g, "") +
            "&region=" +
            this.$store.state.region,
          {
            params: { auth: true },
          }
        )
        .then((response) => {
          this.reservations = response.data;
        })
        .catch((error) => {
          console.error("Error fetching reservations:", error);
        });
    },
    getVehicleImage: function (model) {
      return (
        this.$store.state.user.models.find((r) => r.name === model)
          ?.public_image_link || "default-image-link"
      );
    },
    getRegionPrefix: function (regionId) {
      return (
        this.$store.state.user.regions.find((r) => r.id === regionId)?.prefix ||
        ""
      );
    },
    getVehicleLocation: function (vin) {
      const location_id = this.$store.state.user.vehicles.find(
        (vehicle) => vehicle.vin === vin
      )
        ? this.$store.state.user.vehicles.find((vehicle) => vehicle.vin === vin)
            .location_id
        : null;

      if (location_id == null) {
        return null;
      }
      return this.$store.state.user.locations.find(
        (location) => location.id === location_id
      )
        ? this.$store.state.user.locations.find(
            (location) => location.id === location_id
          ).name
        : "";
    },
  },
};
</script>

<style scoped>
.sticky-column {
  position: sticky; /* Make it sticky on larger screens */
  position: -webkit-sticky; /* Compatibility for older webkit browsers */
  top: 0; /* Necessary for sticky positioning */
  left: 0;
  background-color: white;
  z-index: 2;
}

/* Media query for screens smaller than 768px (Bootstrap md breakpoint) */
@media (max-width: 768px) {
  .sticky-column {
    position: static; /* Non-sticky on small screens */
  }
}
.vehicle-card {
  min-width: 250px !important;
}

.table-responsive {
  overflow-x: auto;
}

.reservation-indicator {
  /* Styles for indicating a reservation on a specific day */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

th {
  white-space: nowrap; /* Prevent wrapping in table headers */
}

/* For sizes smaller than md (768px by default in Bootstrap 5.2) */
@media (max-width: 767.98px) {
  #reservationOffcanvas {
    width: 100% !important;
  }
}
#reservationOffcanvas {
  min-width: 75% !important;
}

thead th {
  position: sticky;
  top: 0;
  background-color: white; /* To ensure the header is not transparent */
  z-index: 1; /* To keep the header above other content */
}
.clickable {
  cursor: pointer;
}
</style>
