<template>
  <div class="card shadow rounded-3 mb-3">
    <div class="card-body">
      <!-- Main Information -->
      <div class="w-100 d-flex mb-3 justify-content-between align-items-center">
        <h3 class="card-title primary-color font-weight-bold fw-600 my-auto">
          {{ reservation.vehicle_model }}
        </h3>
        <h3 class="my-auto">
          <span class="badge float-end badge-secondary">{{
            reservation.status
          }}</span>
        </h3>
      </div>
      <!-- Time Row -->
      <div class="row g-0 mt-1 mb-1">
        <div class="d-flex mx-auto text-start">
          <div class="col-md-3 col-6 d-flex">
            <h5 class="my-auto w-100 me-1">
              {{
                new Date(reservation.pickup_at).toLocaleTimeString("en-US", {
                  timeZone: reservation.pickup_timezone,
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })
              }}
            </h5>
          </div>
          <div class="d-none d-sm-block col-md-6 col-4 my-auto">
            <div class="progress">
              <div
                class="progress-bar"
                :style="{ width: calculateProgressBarWidth(reservation) + '%' }"
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div class="col-md-3 col-6 d-flex">
            <h5 class="my-auto w-100 ps-1 text-end">
              {{
                new Date(reservation.return_at).toLocaleTimeString("en-US", {
                  timeZone: reservation.return_timezone,
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })
              }}
            </h5>
          </div>
        </div>
      </div>
      <!-- Mobile Progress -->
      <div class="d-md-none col-md-12">
        <div class="progress mt-1 mb-1">
          <div
            class="progress-bar"
            :style="{ width: calculateProgressBarWidth(reservation) + '%' }"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
      <!-- Date Row -->
      <div class="row g-0">
        <div class="d-flex mx-auto">
          <div class="col-md-3 col-6 d-flex text-start">
            <p class="my-auto w-100 text-muted">
              {{
                new Date(reservation.pickup_at).toLocaleDateString("en-US", {
                  timeZone: reservation.pickup_timezone,
                  weekday: "short",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })
              }}
            </p>
          </div>
          <div class="col-md-6 d-none d-md-block my-auto">
            <!-- Remaining time -->
            <p class="text-center my-auto">
              <span
                v-if="
                  reservation.status != 'Canceled' &&
                  reservation.status != 'Returned'
                "
                >{{ formatRemainingTime(reservation, "short_label") }}</span
              >
              <span v-if="reservation.status == 'Canceled'">{{
                reservation.status
              }}</span>
              <span
                v-if="
                  reservation.status == 'Returned' && reservation.late == true
                "
                >{{ reservation.status }} (Late)</span
              >
              <span
                v-if="
                  reservation.status == 'Returned' && reservation.late == false
                "
                >{{ reservation.status }}</span
              >
            </p>
          </div>
          <div class="col-md-3 col-6 d-flex">
            <div class="my-auto w-100">
              <p class="my-auto w-100 text-muted text-end">
                {{
                  new Date(reservation.return_at).toLocaleDateString("en-US", {
                    timeZone: reservation.return_timezone,
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Location Row -->
      <div class="row g-0">
        <div class="d-flex mx-auto">
          <div class="col-md-3 col-6 d-flex text-start">
            <p class="my-auto w-100 text-muted me-1">
              {{ reservation.return_location }}
            </p>
          </div>
          <div class="col-md-6 d-none d-md-block my-auto"></div>
          <div class="col-md-3 col-6 d-flex">
            <div class="my-auto w-100">
              <p class="my-auto w-100 text-muted text-end ms-1">
                {{ reservation.pickup_location }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex mt-3">
        <!-- Reservation ID -->
        <p class="d-inline mb-0">#{{ reservation.prefixed_id }}</p>

        <!-- Remaining time -->
        <p
          class="text-center ms-auto mb-0"
          v-if="
            reservation.status == 'On Rent' || reservation.status == 'Pending'
          "
        >
          {{ formatRemainingTime(reservation, "short_label") }}
        </p>
        <p
          class="text-center ms-auto mb-0"
          v-if="reservation.status == 'Returned' && reservation.returned_at"
        >
          {{
            new Date(reservation.returned_at).toLocaleDateString("en-US", {
              timeZone: reservation.return_timezone,
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
          }}
          |
          {{ formatReturnTimeDifference(reservation, "short_label") }}
        </p>

        <!-- If reserved but outstanding balance 
        <router-link
          tag="button"
          :to="'/reservation/' + reservation.hq_id"
          class="btn btn-primary btn-sm ms-auto my-auto"
          >Open →</router-link
        >-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReservationCard",
  props: ["reservation", "user"],
  methods: {
    calculateProgressBarWidth(reservation) {
      const totalMinutes = this.totalTripMinutes(
        reservation.pickup_at,
        reservation.return_at,
        "UTC",
        "UTC"
      );

      const remainingMinutes = this.totalTripMinutesRemaining(
        reservation.return_at,
        "UTC"
      );

      if (remainingMinutes <= 0) {
        // Trip is completed, progress bar should be 100%
        return 100;
      } else {
        // Calculate the percentage of trip completed
        const percentage =
          ((totalMinutes - remainingMinutes) / totalMinutes) * 100;
        return Math.min(percentage, 100); // Ensure the progress bar doesn't exceed 100%
      }
    },

    totalTripMinutes(start, end, startTimeZone, endTimeZone) {
      // Calculate total trip minutes
      const startDate = new Date(
        new Date(start).toLocaleString("en-US", { timeZone: startTimeZone })
      );
      const endDate = new Date(
        new Date(end).toLocaleString("en-US", { timeZone: endTimeZone })
      );
      return (endDate.getTime() - startDate.getTime()) / 1000 / 60;
    },

    totalTripMinutesRemaining(end, endTimeZone) {
      // Calculate remaining trip minutes
      const now = new Date(
        new Date().toLocaleString("en-US", { timeZone: endTimeZone })
      );
      const endDate = new Date(end);
      return (endDate.getTime() - now.getTime()) / 1000 / 60;
    },
    formatRemainingTime: function (reservation, label) {
      const now = new Date(
        new Date().toLocaleString("en-US", {
          timeZone: reservation.pickup_timezone,
        })
      );
      const tripStart = new Date(
        new Date(reservation.pickup_at).toLocaleString("en-US", {
          timeZone: reservation.pickup_timezone,
        })
      );

      if (now < tripStart) {
        // Calculate and display time until trip starts
        const minutesUntilStart = Math.floor((tripStart - now) / 1000 / 60);

        if (minutesUntilStart < 60) {
          return label === "short_label"
            ? `${minutesUntilStart} minutes until pickup`
            : `${minutesUntilStart} mins left`;
        } else {
          const hoursUntilStart = Math.floor(minutesUntilStart / 60);
          if (hoursUntilStart > 48) {
            const daysUntilStart = Math.floor(hoursUntilStart / 24);
            return label === "short_label"
              ? `${daysUntilStart} days until pickup`
              : `${daysUntilStart} days until pickup`;
          } else {
            return label === "short_label"
              ? `${hoursUntilStart} hours until pickup`
              : `${hoursUntilStart} hrs until pickup`;
          }
        }
      } else {
        // Calculate and display time until trip ends
        const remainingMinutes = this.totalTripMinutesRemaining(
          reservation.return_at,
          reservation.return_timezone
        );

        if (remainingMinutes >= 0) {
          if (remainingMinutes < 60) {
            return label === "short_label"
              ? `${remainingMinutes.toFixed(2)} minutes until return`
              : `${Math.floor(remainingMinutes)} min left`;
          } else {
            const hours = Math.floor(remainingMinutes / 60);
            if (hours > 48) {
              const days = Math.floor(hours / 24);
              return label === "short_label"
                ? `${days} days left`
                : `${days} days until return`;
            } else {
              return label === "short_label"
                ? `${hours} hours left`
                : `${hours} hrs until return`;
            }
          }
        } else {
          const lateMinutes = -remainingMinutes;
          if (lateMinutes < 60) {
            return label === "short_label"
              ? `${lateMinutes.toFixed(2)} minutes late`
              : `${Math.floor(lateMinutes)} mins late`;
          } else {
            const hoursLate = Math.floor(lateMinutes / 60);
            if (hoursLate > 48) {
              const daysLate = Math.floor(hoursLate / 24);
              return label === "short_label"
                ? `${daysLate} days late`
                : `${daysLate} days late`;
            } else {
              return label === "short_label"
                ? `${hoursLate} hours late`
                : `${hoursLate} hrs late`;
            }
          }
        }
      }
    },
    formatReturnTimeDifference: function (reservation, label) {
      const returnedAt = new Date(reservation.returned_at);
      const tripEnd = new Date(reservation.return_at);
      const timeZoneOptions = {
        timeZone: reservation.return_timezone,
        hour12: false,
      };

      // Adjust dates to the reservation's timezone
      returnedAt.toLocaleString("en-US", timeZoneOptions);
      tripEnd.toLocaleString("en-US", timeZoneOptions);

      // Calculate the time difference in minutes
      const timeDiffMinutes = (tripEnd - returnedAt) / 60000; // Convert milliseconds to minutes

      // Determine the label type
      const isShortLabel = label === "short_label";
      console.log(timeDiffMinutes);
      console.log(Math.floor(timeDiffMinutes));

      if (
        timeDiffMinutes >= 0 ||
        (timeDiffMinutes < 1 && timeDiffMinutes > -1)
      ) {
        // Trip ended early
        if (timeDiffMinutes < 1 && timeDiffMinutes > -1) {
          return "on time";
        }
        if (timeDiffMinutes < 60) {
          return isShortLabel
            ? `${timeDiffMinutes.toFixed(2)} minutes early`
            : `${Math.floor(timeDiffMinutes)} min early`;
        }
        const hours = Math.floor(timeDiffMinutes / 60);
        if (hours >= 48) {
          const days = Math.floor(hours / 24);
          return isShortLabel ? `${days} days early` : `${days} days early`;
        }
        return isShortLabel ? `${hours} hours early` : `${hours} hrs early`;
      } else {
        // Trip has ended and was returned late
        const lateMinutes = Math.abs(timeDiffMinutes);
        if (lateMinutes < 60) {
          return isShortLabel
            ? `${Math.floor(lateMinutes.toFixed(2))} minutes late`
            : `${Math.floor(lateMinutes)} mins late`;
        }
        const hoursLate = Math.floor(lateMinutes / 60);
        if (hoursLate >= 48) {
          const daysLate = Math.floor(hoursLate / 24);
          return isShortLabel
            ? `${daysLate} days late`
            : `${daysLate} days late`;
        }
        return isShortLabel
          ? `${hoursLate} hours late`
          : `${hoursLate} hrs late`;
      }
    },
  },
};
</script>
